

export const getChangeProcessC2cReducer = store => {

    return store.ChangeProcessC2CReducer;

}


export const getDialogReducer = store => {

    return store.DialogReducer;

}

export const getWebSiteInfo = store => {

    return store.WebsiteInfoReducer;

} 