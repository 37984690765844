import React from 'react';
import {
  makeStyles,
} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    //  alignItems: "center",
    //  alignContent:"center",
  },
  layout: {
    alignContent: "center",
    justifyItems: "center",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    "& span": {
      fontFamily: "IRANSans",
    },
    bottom: theme.spacing(-2),
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(8),
    borderRadius: theme.spacing(5),
    background: "#4CAF50",
  },
  boxMain: {
    boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    background: "#fff",
    WebkitBorderRadius: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: 0,
    margin: 0,
  },

  box: {
    // boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    // background: "red",
    // WebkitBorderRadius: theme.spacing(1),
    // borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  anim: {
    opacity: 1,
    transform: "scale(1)",
    transition: "opacity 800ms,transform 800ms",
  },
  btn3: {
    "& span": {
      direction: "ltr !important",
      textDecoration: "ltr !important",

    },
    backgroundColor: "#00C85330",
    borderRadius: "6px",
    color: "#00C853 !important",
    border: " 1px solid #00C853",
    direction: "rtl",
    "&:hover": {
      backgroundColor: "#00C85340 !important",
      border: " 1px solid #00C853",
    },
    "&:focus": {
      backgroundColor: "#00C85330 !important",
      border: " 1px solid #00C853",
    },
    height: "50px",
  },


  btn: {
    "& span": {
      direction: "ltr !important",
      textDecoration: "ltr !important",

    },
    backgroundColor: "#8E24AA30",
    borderRadius: "6px",
    color: "#8E24AA !important",
    border: " 1px solid #8E24AA",
    direction: "rtl",
    "&:hover": {
      backgroundColor: "#8E24AA40 !important",
      border: " 1px solid #8E24AA",
    },
    "&:focus": {
      backgroundColor: "#8E24AA30 !important",
      border: " 1px solid #8E24AA",
    },
    height: "50px",
  },
  btn2: {
    "& span": {
      direction: "ltr !important",
      textDecoration: "ltr !important",

    },

    backgroundColor: "#3e8ae630",
    borderRadius: "6px",
    color: "#3f95fc !important",

    border: " 1px solid #3e8ae6",
    "&:hover": {
      backgroundColor: "#3e8ae630 !important",
      border: " 1px solid #3e8ae6",
    },
    "&:focus": {
      backgroundColor: "#3e8ae630 !important",
      border: " 1px solid #3e8ae6",
    },
    height: "50px",

  },
}));