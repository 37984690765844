import React, { Component, Suspense, useEffect, useState } from "react";
import { HashRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";

import routes from "./routes";
import { useStyles, jss, theme1 } from "./assets/styles/App_style";

import { Container, Grid } from '@material-ui/core';
import "./assets/styles/main.scss";
import withClearCache from "./ClearCache";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { zoomIn, flipInX } from "react-animations";
import styled, { keyframes } from "styled-components";
//import BtnFooter from "./views/component/btnFooter";
import LoadingView from './views/containers/cpg/loadingPage'
import Api from './api/Api'
import { connect } from "react-redux";
import { getWebSiteInfo } from './redux/selectors'


const mapStateToProps = (state) => {
  const { info } = getWebSiteInfo(state);
  return { info };
};


const Copyright = connect(mapStateToProps)(({ info }) => {
  const [_info, setInfo] = useState()
  useEffect(() => {


    if (info)
      setInfo(info)
  }, [info])
  return (
    <Grid
      item
      xs={12}
      style={{ display: (_info && _info.showCopyright) ? "inline" : "none", marginTop: _info && _info.showCustomSupportId ? 15 : 80, marginBottom: 15 }} id="copyRight">
      <Typography variant="body2" color="textSecondary" align="center">
        Powered by
        <Link
          target="_blank"
          color="inherit"
          href={(_info && _info.showCustomSupportId) ? `https://t.me/${_info.supportId}` : "https://t.me/central_pay"}

          style={{ color: "rgb(179, 136, 57)" }}
        >
          &nbsp; CentralPay
        </Link>{" "}
      </Typography>
    </Grid>
  );
})

// Containers



const App = () => {
  let navigate = useNavigate()
  const classes = useStyles();
  const myRef = React.useRef(null);
  const [logo, setLogo] = React.useState();

  window.onpageshow = () => {
    window.sessionStorage.setItem("isSet", false);
  };

  window.onbeforeunload = () => {
    window.sessionStorage.setItem("isSet", false);
  };
  const setNavigate = (path, data = null) => {


    navigate(`/${path}`, { state: data })
  }

  const bounceAnimation = keyframes`${zoomIn}`;

  const BouncyDiv = styled.div`
    animation: 0.7s ${bounceAnimation};
  `;
  const addCardAnimation = keyframes`${flipInX}`;




  const request = async () => {
    try {

      const { status, data } = await Api.checkSessionValidity(sessionStorage.getItem("sessionId"));

      if (status === 200) {
        if (!data.success) {
          localStorage.clear();
          sessionStorage.clear()

          window.sessionStorage.setItem("message", data.message)

          window.sessionStorage.setItem(
            "webSiteInfo",
            JSON.stringify(data.websiteInfo)
          );
          sessionStorage.setItem("isFielad", true)
          setTimeout(() => {
            navigate("fielad", { state: null })
          }, 500);

        }
      }

    } catch (error) {
    }
  };

  useEffect(() => {
    try {
      if (!window.location.href.includes("loading") && sessionStorage.getItem("sessionId") !== null)
        request()
    } catch (e) { }
  }, [])

  return (

    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme1}>

        {window.sessionStorage.setItem("url_", window.location.href)}
        <Container
          component="main"
          maxWidth="sm"
          style={{ minHeight: "100vh", }}
          justify
        >
          <Grid
            container
            direction="column"
            justify="space-between"
            alignItems="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid container justify="center" direction="row">
              <Grid item>
                <div className={classes.paper} ref={myRef}>
                  {logo ? (
                    <img

                      src={`../websitesLogo/${logo}`}
                      alt="logoMainPage"
                      style={{
                        maxHeight: 55,
                      }}
                    />
                  ) : (
                    <div style={{ width: "160px", height: "150px" }}></div>
                  )}



                  <Routes >

                    <Route
                      path="*"
                      element={<LoadingView setLogo={setLogo} setNavigate={setNavigate} />}
                    />

                    {
                      routes.map((route_) => {
                        const Element = route_.component
                        return (
                          <Route
                            path={route_.path}
                            element={
                              <BouncyDiv>
                                <Element setLogo={setLogo} setNavigate={setNavigate} />
                              </BouncyDiv>
                            }
                          />
                        )
                      }
                      )
                    }

                  </Routes>


                </div >
              </Grid >
            </Grid >


            <Grid item style={{ marginTop: 10 }}>
              {/* <BtnFooter info={info} /> */}
              <Copyright />
            </Grid>
          </Grid >

        </Container >
      </ThemeProvider >
    </StylesProvider >

  );
};


export default App;
