import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
} from "@material-ui/core";
import { usePaymentInputs } from "react-payment-inputs";

import Api from "../../../../api/Api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  editPhoneText: {
    fontSize: "13px",
    color: "#0087c9",
    // color: "#039BE5", 
  },
}));


const InputCard = ({ setPhone, toNext, setToNext, enterClick }) => {
  const classes = useStyles();
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const [input1, setInput1] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const myRef = React.useRef(null);
  const myRef2 = React.useRef(null);
  const myRef3 = React.useRef(null);
  const myRef5 = React.useRef(null);

  React.useEffect(() => {
    setIsEdit(toNext);
  }, [toNext]);





  const handleChangeCardNumber1 = (e) => {

    let numberPhone = e.target.value
    setInput1(numberPhone);
    if (numberPhone.length <= 15) {

      setPhone(numberPhone);
    }




    // if (e.target.value.length == 11) {
    //   setInput1(e.target.value);
    //   setEnableBtn(true);

    // } else if (e.target.value.length < 11) {
    //   setInput1(e.target.value);
    //   setEnableBtn(false);
    // }
  };

  const handleClickEnter = (e) => {

    if (e.key === 'Enter') {
      enterClick()
    }
  }
  const handleChangeCardNumber = (ref, changeState, input) => (e) => {
    if (e.target.validity.valid) {
      changeState(e.target.value);
      if (input.length > 2) ref.current.focus();
    }
  };


  return (
    <Grid
      container
      xs={12}
      direction="row-reverse"
      justify="center"
      alignItems="center"
      spacing={0}
    >
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignContent="center"
        justifyContent="center"
        xs={9}
        style={{
          marginLeft: 15,
          marginRight: 20,
          textAlign: "end",
          fontSize: "14px",
        }}
      >
        <Grid item> &nbsp;
          <span style={{ color: "grey" }}>  ( {` مربوط به شماره همراه ${sessionStorage.getItem("phonePay")}`}) </span>

          <span>  کد ملی</span>


          &nbsp;</Grid>
        <div
          style={{
            borderRadius: "50%",
            width: "4px",
            height: "4px",
            background: "rgba(0,0,0,0.5)",
            marginTop: "9px",
          }}
        ></div>
      </Grid>



      <Grid
        container
        xs={9}
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={!isEdit ? "layoutInput m-t-15" : "layoutInputDiable m-t-15"}

        spacing={0}
      >
        <Grid container xs={4} justify="space-around" alignContent="center" direction="row"  >



        </Grid>

        <Grid item xs={12}>
          <input
            className={`${"inputCard holder"} `}
            type="tel"
            pattern="[0-9]*"
            onInput={handleChangeCardNumber1}
            value={input1}
            maxLength={11}
            placeholder={"   کدملی را وارد کنید "}
            disabled={isEdit}
            ref={myRef}
            onKeyDown={handleClickEnter}
          />
        </Grid>

      </Grid>
    </Grid>
  );
};

export default InputCard;
