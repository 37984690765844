import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Button, Box } from "@material-ui/core";
import { Player } from "@lottiefiles/react-lottie-player"; 

import { Navigate, Link } from "react-router-dom";
import { useStyles } from "./style";
import { setTheme, containsNumber } from "../../../../tools/Tools";
let setIsTheme = false;
let textBtnRedirect = "بازگشت به سایت پذیرنده ";
const PaymentSuccess = ({ setLogo, setNavigate }) => {
  const classes = useStyles();
  const [isRedirect, setIsRedirect] = useState();
  const [infoSite, setInfoSite] = useState({});
  const [click, setClick] = useState(false);
  React.useEffect(() => {
    if (!setIsTheme) {
      setIsTheme = true;
      setTheme(setLogo);
    }
  }, []);

  useEffect(() => {
    if (!window.sessionStorage.getItem("isSuccessC2C"))
      setNavigate("loading")
    const info = window.sessionStorage.getItem("webSiteInfo");
    if (info) setInfoSite(JSON.parse(info));
  }, []);

  return (

    <Box style={{ marginTop: "40px" }}>
      <Grid
        container
        spacing={0}
        className={classes.boxMain}
        justify="center"
        direction="row"
        xs={12}
      >
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            marginTop: "23px",
            fontFamily: "IRANSans",
          }}
        >
          پرداخت با موفقیت انجام شد
        </Grid>

        <Grid container direction="row" justify="center" className="codeLastStep" style={{ textAlign: "center" }}>
          <Grid item xs={6} > {window.sessionStorage.getItem("depositId")}</Grid>
          <Grid item style={{ fontSize: "10px", color: "grey" }} className="un-selected"> شناسه پیگیری داخلی</Grid>
        </Grid>



        {isRedirect && (
          <Grid
            item
            xs={12}
            style={{ color: "grey", textAlign: "center", fontSize: "13px" }}
          >
            ...تا لحظاتی دیگر به سایت پذیرنده هدایت میشوید
          </Grid>
        )}
        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
          >
            <a
              href={infoSite.websiteAddress}
              style={{
                color: "white",
                textDecoration: "none",
                direction: "rtl",
              }}
              target="_self"
            >
              بازگشت به {containsNumber(infoSite?.websiteName) ? <span className="unSetFont"> {infoSite?.websiteName}</span> : infoSite.websiteName}
            </a>
          </Button>
        </Grid>
      </Grid>
    </Box>

  );
};
export default PaymentSuccess;
