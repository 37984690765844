import React, { Component, useState } from "react";
import {
  Grid, 
  Box, 
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"; 
import MuiAlert from "@material-ui/lab/Alert";   
const useStyles = makeStyles((theme) => ({
   
   
  boxMain: {
    boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    background: "#fff",
    WebkitBorderRadius: theme.spacing(1),
    borderRadius: theme.spacing(2),
    
  },

  
  layoutTitle: { 
    textAlign: "center", 
    color: "white", 
    background: "rgba(57, 57, 57,1)",
    borderBottomRightRadius: "50%", 
    borderBottomLeftRadius: "50%", 
    borderTopRightRadius:  theme.spacing(2), 
    borderTopLeftRadius:  theme.spacing(2), 
    width:theme.spacing(60), 
    marginBottom:theme.spacing(5),
    height:theme.spacing(8), 
    
  },
  layoutTitle2: { 
    textAlign: "center", 
    color: "rgba(57, 57, 57,1)",    
    marginBottom:theme.spacing(5),
    height:theme.spacing(15),
    
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const NotActive = ({ webSiteName }) => { 
  const classes = useStyles();
  const [cardNumber, setCardNumber] = useState(null);
  const [loading, setloading] = useState(false);
  const [enableBtn, setEnableBtn] = useState(false);
  const [isResult, setIsResult] = useState({
    result: false,
    msg: "شماره کارت وارد نشده است",
    isSuccess: false,
  });
  
  const [msg, setMsg] = useState("خطا از سمت سرور ");

  const clickBtn = () => {
   
     setloading(true);
    setTimeout(() => {
      setloading(false);
        setIsResult({...isResult,result:true})
      
    }, 1000);
  };
  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsResult({...isResult,result:false})
  };
  return (  
       <Box height="65vh">
        <Grid container direction="row"
            justify="center"
            alignItems="center" 
            className={classes.boxMain} 
            spacing={3}
            >
           
          <Grid item className={classes.layoutTitle} xs={12}>
           کارت با موفقیت ثبت شد
          </Grid>

          {/* <Grid item className={classes.layoutTitle2} xs={12}>
           لطفا منتظر بمانید تا کارت تایید شود
          </Grid> */}

          <Grid item className={classes.layoutTitle2} xs={12}>
           لطفا منتظر بمانید تا کارت تایید شود
          </Grid>
          
        </Grid>
       

             
      </Box> 
     
  
     
  );
};
export default NotActive;
