import React, { useState, useEffect } from "react";
import {
  Grid,
  Avatar,
} from "@material-ui/core";
import { usePaymentInputs } from "react-payment-inputs";
import CardBank from "./CardBank";
import PaymentIcon from "@material-ui/icons/Payment";
import { getLogo } from './ListBank'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { convertNumbers2EnglishString, replaceAllSpace } from '../../../../tools/Tools'
//import img from '../../../assets/banksLogo/2.png'

import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

}));







const InputCard = ({ setEnableBtn, setCardNumber, enterClick }) => {
  const classes = useStyles();
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const [input1, setInput1] = useState("");
  const [bankLogo, setBankLogo] = useState(null);
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const myRef = React.useRef(null);
  const myRef2 = React.useRef(null);
  const myRef3 = React.useRef(null);
  const myRef5 = React.useRef(null);
  const handleClickEnter = (e) => {

    if (e.key === 'Enter') {
      enterClick()
    }
  }
  const handleChangeCardNumber1 = (e) => {

    let addressLogo = getLogo(convertNumbers2EnglishString(e.target.value))
    setBankLogo(addressLogo)






    if (input1.length < e.target.value.length || e.target.value.length == 16) {

      if (!(/[a-zA-Z]/).test(e.target.value)) {
        //  setInput1(e.target.value);
        const value = e.target.value.replace(/\W/gi, "").replace(/(.{4})/g, "$1  ")

        if (value.trim().length < 23)
          setInput1(value.trim());

        if (value.trim().length == 22) {
          setEnableBtn(true)
          setCardNumber(replaceAllSpace(e.target.value))
          // setCardNumber((e.target.value).replaceAll(" ",""))
        }

      }
    } else {
      if (e.target.value.length < 23)
        setInput1(e.target.value);
      setEnableBtn(false)

    }
  };

  const handleChangeCardNumber22 = (e) => {


    let value = replaceAllSpace(e.target.value)
    if (!(/[a-zA-Z]/).test(value)) {
      value = convertNumbers2EnglishString(value);
      let addressLogo = getLogo(value)
      setBankLogo(addressLogo)


      if (value.length === 16) {
        e.target.blur()

        const valueNew = value.replace(/\W/gi, "").replace(/(.{4})/g, "$1  ").trim()
        setInput1(valueNew);
        setCardNumber(value)
        setEnableBtn(true)
      } else if (value.length <= 16) {
        setInput1(value);
        setEnableBtn(false)
      }
    }


  };
  const handleChangeCardNumber = (ref, changeState, input) => (e) => {
    if (e.target.validity.valid) {
      changeState(e.target.value);
      if (input.length > 2) ref.current.focus();
    }
  };

  const handleChangeCardNumber2 = (e) => {
    if (input4.length > 3) {
      myRef3.current.disabled = true;
      myRef3.current.disabled = false;
    } else setInput4(e.target.validity.valid ? e.target.value : input4);
  };
  return (
    <Grid
      container
      xs={12}
      direction="row-reverse"
      justify="center"
      alignItems="center"
      style={{ marginTop: 50 }}
      spacing={0}
    >

      <Grid
        container
        direction="row"
        justify="flex-end"
        alignContent="center"
        justifyContent="center"
        xs={9}
        style={{
          marginLeft: 15,
          marginRight: 20,
          textAlign: "end",
          fontSize: "14px",
        }}
      >
        <Grid item> &nbsp;   شماره کارت   &nbsp;</Grid>
        <div
          style={{
            borderRadius: "50%",
            width: "4px",
            height: "4px",
            background: "rgba(0,0,0,0.5)",
            marginTop: "9px",
          }}
        ></div>
      </Grid>

      <Grid
        container
        xs={9}
        direction="row"
        justify="center"
        alignItems="center"
        className="layoutInput"
        spacing={0}
        style={{ marginTop: 10 }}
      >
        <Grid
          container
          xs={2}
          direction="row"
          justify="center"
          alignItems="center"
        >

          {
            bankLogo != null ? <Avatar alt="logo bank" src={`./banksLogo/${bankLogo}.png`} className={classes.small} /> :
              <HelpOutlineIcon color="disabled" />
          }
        </Grid>
        <Grid item xs={8}>
          <input
            className="inputCard"
            type="tel"
            onChange={handleChangeCardNumber22}
            value={input1}
            maxLength={22}
            placeholder={"شماره کارت"}
            style={{ fontSize: "14px", direction: "ltr" }}
            onKeyDown={handleClickEnter}
            onFocus={() => setInput1(replaceAllSpace(input1))}
            onBlur={() => setInput1(input1.replace(/\W/gi, "").replace(/(.{4})/g, "$1  ").trim())}
          />
        </Grid>
        <Grid
          container
          xs={2}
          direction="row"
          justify="flex-end"
          alignItems="center"

          style={{ paddingLeft: 6, paddingRight: 8 }}
        >
          <PaymentIcon fontSize="default" style={{ color: 'rgba(57, 57, 57,0.9)' }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InputCard;
