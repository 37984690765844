import React, { Component, useEffect, useState } from "react";
import {
  Grid,
  CardContent,
  Button,
  Box,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  Avatar,
  FormControlLabel,
} from "@material-ui/core";

import { useLocation } from "react-router-dom";
import { getLogo, getNumberCardFromatter } from "../addCardPage/ListBank";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useStyles } from "./style";
import Timer from "./Timer";
import {
  setTheme,
  getArrayNumberCard,
  convertNumberToPice,
  convertNumbers2English,
  convertNumbers2EnglishString,
  resulation,
  setNumberCard,
  showErrorRequest,
} from "../../../../tools/Tools";
import LoadingProcess from "../LoadingProcess";
import DialogSuccess from "../dialogs/DialogSuccess";
import DialogError from "../dialogs/DialogError";
import DialogWarring from "../dialogs/DialogWarring";
import DialogCode from "../dialogs/DialogCode";
import Api from "../../../../api/Api";
import InputNationalCode from "./InputNationalCode";

import DialogLoading2 from "../../c2c/dialogs/DialogLoading2";
let time;
let setIsTheme = false;

let confirmCodeImageId = -1;
let cardData = {
  cvv2: null,
  expireMonth: "-1",
  expireYear: "-1",
  saveCardData: false,
  pin2: "-1",
};
let loackRequestCancel = false;
const LastStepPage = ({ webSiteName, setLogo, setNavigate }) => {
  const { state } = useLocation();
  const classes = useStyles();
  const [listYear, setListYear] = useState([]);

  const [cardInfo, setCardInfo] = useState({
    month: -1,
    year: -1,
    cvv2: null,
  });
  const [month, setMonth] = useState(-1);
  const [year, setYear] = useState(-1);
  const [formData, setFormData] = useState();
  const [loading, setloading] = useState(false);
  const [enableBtn, setEnableBtn] = useState(false);
  const [sendMsg, setSendMsg] = React.useState(false);
  const [timeForm, setTimeForm] = React.useState();
  const [isResult, setIsResult] = useState({
    result: false,
    msg: "",
    isSuccess: false,
  });

  const myRef = React.useRef(null);
  const myRefMonth = React.useRef(null);
  const [msg, setMsg] = useState("خطا از سمت سرور ");
  const [age, setAge] = React.useState("5");
  const [openDialogAddCard, setOpenDialogAddCard] = React.useState(false);
  const [autofillCardInputs, setAutofillCardInputs] = React.useState();
  const [openSelectedYear, setOpenSelectedYear] = React.useState(false);
  const [cvv2, setCvv2] = React.useState();
  const [endProcess, setEndProcess] = React.useState(false);
  const [pin2, setPin2] = React.useState();
  const [isShowCodeDialog, setIsShowCodeDialog] = React.useState({
    open: false,
    isOtp: false,
  });
  const [showSaveCardDataOption, setShowSaveCardDataOption] = React.useState(
    false
  );

  React.useEffect(() => {
    if (endProcess) {
      setloading(false);
    }
  }, [endProcess]);

  const handleCloseDialogError = () => {
    if (isResult.endTimeFrom) {
      cardData = {
        cvv2: null,
        expireMonth: "-1",
        expireYear: "-1",
        saveCardData: false,
        pin2: "-1"
      }
      const url = sessionStorage.getItem("url_")
      const sessionId = sessionStorage.getItem("sessionId")
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem("url_", url)
      sessionStorage.setItem("sessionId", sessionId)
      setNavigate("loading")
    }
    setIsResult({ ...isResult, result: false });

  };
  const handleChange = (event) => {
    sessionStorage.setItem("showExpanded", true);
    setAge(event.target.value);
  };
  const handleChangeAutoFill = () => {
    cardData.saveCardData = !autofillCardInputs;
    setAutofillCardInputs(!autofillCardInputs);
  };

  const handleSendMsg = (event) => {
    if (month !== -1 && year !== -1) {
      setloading(true);

      setTimeout(() => {

        requestOTPRequest();
        // setloading(false)
        // setIsResult({ ...isResult, result: true, msg:'send massege',isSuccess:2 ,timer:5});
      }, 400);
    } else setIsResult({ msg: " لطفا تاریخ انقضاء را وارد کنید ", isSuccess: 0, result: true });

  };

  const handleBtnSubmit = () => {
    if (cvv2) {
      if (month != -1) {
        if (year != -1) {
          if (pin2) {
            setloading(true);
            setTimeout(() => {
              requestTransfer();
            }, 350);
          } else {
            setIsResult({
              msg: " رمز دوم یا رمز پویا را وارد کنید",
              isSuccess: 0,
              result: true,
            });
          }
        } else {
          setIsResult({ msg: " سال را وارد کنید", isSuccess: 0, result: true });
        }
      } else {
        setIsResult({ msg: " ماه را وارد کنید", isSuccess: 0, result: true });
      }
    } else {
      setIsResult({
        msg: "شماره شناسایی را وارد کنید",
        isSuccess: 0,
        result: true,
      });
    }
  };

  const handleBtnCancel = () => {
    //  window.parent.document.body.style.zoom = 1.5;
    //alert(window.parent.document.body.style.zoom.toString());
    //document.body.classList.remove("hide-text");
    setIsResult({
      result: true,
      msg: "آیا از لغو تراکنش اطمینان دارید؟",
      isSuccess: 1,
    });
  };

  const showCheckBoxSaveInfoCard = () => {
    if (!showSaveCardDataOption) {
      setShowSaveCardDataOption(true);
      setAutofillCardInputs(false);
    }
  };
  const handleChangeCVV2 = (e) => {
    setCvv2(e.target.value);
    showCheckBoxSaveInfoCard();
  };
  const handleKeyDownCVV2 = (e) => {
    if (e.key === "Enter") {
      myRef.current.focus();
    }
  };
  const handleChangeMonth = (event) => {
    showCheckBoxSaveInfoCard();
    setMonth(event.target.value);
  };
  const handleChangeYear = (event) => {
    showCheckBoxSaveInfoCard();
    setYear(event.target.value);
  };

  const handleChangePin2 = (e) => {
    const value = e.target.value;
    if (value.length < 12)
      setPin2(convertNumbers2EnglishString(value));
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      },
    },
  };
  const requestCancelDeposit = async () => {
    loackRequestCancel = true;
    try {
      const { data } = await Api.cancelDeposit();
      const url = sessionStorage.getItem("url_")
      const sessionId = sessionStorage.getItem("sessionId")
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem("url_", url)
      sessionStorage.setItem("sessionId", sessionId)
      cardData = {
        cvv2: null,
        expireMonth: "-1",
        expireYear: "-1",
        saveCardData: false,
        pin2: "-1"
      }
      setIsResult({ result: false });
      setTimeout(() => {
        setNavigate("loading")
        loackRequestCancel = false;
      }, 200);
    } catch (error) { }
  };

  const handleBackDialog = () => {
    setIsShowCodeDialog({ open: false, isOtp: false });
  };


  const handleCloseDialogCode = () => {
    if (cardData.confirmCode.length > 2) {
      const isOtp = isShowCodeDialog.isOtp;
      setIsShowCodeDialog({ open: false, isOtp: false });
      setTimeout(() => {
        setloading(true)
        if (isOtp) requestOTPRequest(cardData.confirmCode);
        else
          requestTransfer();
      }, 600);

    }
  };

  const handleSubmitDialogCode = (confirmCode) => {
    if (confirmCode.length > 2) {
      const isOtp = isShowCodeDialog.isOtp;
      setIsShowCodeDialog({ open: false, isOtp: false });
      setTimeout(() => {
        setloading(true)
        if (isOtp) requestOTPRequest(confirmCode);
        else
          requestTransfer(confirmCode);
      }, 600);

    }
  };

  const handleCloseAndBackDialogWarring = () => {
    if (!loackRequestCancel)
      requestCancelDeposit();
  };
  const handleCloseDialogWarring = () => {
    setIsResult({ result: false });
  };
  const handleCloseDialogSuccess = () => {
    setIsResult({ result: false });
    if (isResult?.timer) {
      time = new Date();
      time.setSeconds(time.getSeconds() + Number(isResult?.timer));

      setSendMsg(true);
      setTimeout(() => {
        myRef.current.focus();
      }, 700);
    } else {
      if (isResult?.redirectUrl) {
        window.sessionStorage.setItem(
          "redirectUrl",
          JSON.stringify(isResult?.redirectUrl)
        );
        setNavigate("paymentSuccess");
        // setIsResult({  result: true, msg: 'پرداخت با موفقیت انجام شد',isSuccess:2,redirectUrl:'www.google.com'});
      }
    }
  };

  // ارسال    رمز دوم
  const requestOTPRequest = async (confirmCode) => {
    try {
      let nationalCode = sessionStorage.getItem("nationalCode") != "null" && sessionStorage.getItem("nationalCode")
        ? convertNumbers2EnglishString(sessionStorage.getItem("nationalCode"))
        : "-1";
      let year__ = year != -1 ? `${listYear?.[year]}` : "-1";
      cardData.expireYear = (year__ != "-1") ? year__.substring(2, 4) : year__;
      cardData.expireMonth = month != -1 ? `${getListMonth()?.[month]}` : "-1";
      let after = {};
      if (confirmCode)
        after = { confirmCode: convertNumbers2EnglishString(confirmCode) };
      cardData.cvv2 = cvv2 ? cvv2 : "-1";


      const { status, data } = await Api.OTPRequest(nationalCode, cardData, after);

      setloading(false);
      if (status === 200) {
        if (data.success) {
          setIsResult({
            ...isResult,
            result: true,
            msg: data.message,
            isSuccess: 2,
            timer: data.OTPRequest_setCountdownTimer,
          });
        } else if (data.position === "confirmCode") {
          confirmCodeImageId = data.confirmCodeImageId;
          setIsShowCodeDialog({ open: true, isOtp: true });
        } else setIsResult({ result: true, msg: data.message, isSuccess: 0 });


      } else {
        setIsShowCodeDialog({ open: false, isOtp: false });
        setIsResult({ result: true, msg: showErrorRequest(status), isSuccess: 0 });
      }

    } catch (error) {
      setIsShowCodeDialog({ open: false, isOtp: false });
      setIsResult({ result: true, msg: showErrorRequest(0), isSuccess: 0 });
    }
  };

  const requestTransfer = async (confirmCode) => {
    try {
      cardData.pin2 = pin2;
      cardData.saveCardData = cardData.saveCardData ? 1 : 0;
      let year__ = `${listYear?.[year]}`;
      cardData.expireYear = year__.substring(2, 4);
      cardData.expireMonth = `${getListMonth()?.[month]}`;
      cardData.cvv2 = cvv2;
      const nationalCode = convertNumbers2EnglishString(sessionStorage.getItem("nationalCode"));

      let extra = {};
      if (confirmCode)
        extra.confirmCode = convertNumbers2EnglishString(confirmCode);

      if (nationalCode != null || nationalCode !== 0)
        extra.nationalCode = nationalCode;
      const { status, data } = await Api.transfer(cardData, extra);


      setloading(false);
      if (status === 200) {
        if (data.success) {
          sessionStorage.setItem('isSuccessCPG', true)
          sessionStorage.setItem('autoRedirect', data.autoRedirect)
          if (data.showVoucher)
            sessionStorage.setItem('voucher', data.voucher)
          window.sessionStorage.setItem("depositId", data.depositId);
          window.sessionStorage.setItem("redirectUrl", data.redirectUrl);
          setNavigate("paymentSuccess");

        } else {
          if (data.position == "confirmCode") {
            confirmCodeImageId = data.confirmCodeImageId;
            setIsShowCodeDialog({ open: true, isOtp: false });
          } else setIsResult({ result: true, msg: data.message, isSuccess: 0 });
        }
      } else {
        setloading(false);
        setIsResult({ result: true, msg: showErrorRequest(status), isSuccess: 0, });
      }

    } catch (error) {
      setloading(false);
      setIsResult({ result: true, msg: showErrorRequest(0), isSuccess: 0, });
    }
  };

  const requestGetRemainedTime = async () => {
    try {
      const { status, data } = await Api.getRemainedTime();

      if (status === 200) {
        let timeForm_ = new Date();
        timeForm_.setSeconds(
          timeForm_.getSeconds() + (data.remainedTime ? data.remainedTime : 500)
        );
        setTimeForm(timeForm_);
      }
    } catch (error) { }
  };

  const getListMonth = () => {
    let listMonth = [];
    for (let i = 1; i < 13; i++) {
      listMonth[i] = i < 10 ? `0${i}` : i.toString();
    }
    return listMonth;
  };

  React.useEffect(() => {
    if (!JSON.parse(window.sessionStorage.getItem("isLastStep"))) {

      setNavigate("loading")
    }

    if (JSON.parse(window.sessionStorage.getItem("isHome")) && !setIsTheme) {
      setIsTheme = true;
      setTheme(setLogo);

      setTimeout(() => {
        window.scrollTo(0, 8500);
      }, 900);
    }
  }, []);

  React.useEffect(() => {

    //  if (setIsTheme) {
    if (state) {
      if (state.year) {
        setListYear(state.year)
      }
      window.sessionStorage.setItem("gatewayName", state.gatewayName)
      if (state.autofillCardInputs) {
        //  cardData={...cardData,expireMonth:infoPay_.expireMonth,expireYear:infoPay_.expireYear,cvv2:infoPay_.cvv2}
        setMonth(getListMonth().indexOf("" + state.expireMonth));


        const year = state.expireYear.toString().length == 2 ? "14" + state.expireYear : state.expireYear

        setYear(state.year.indexOf(parseInt(year)))
        setCvv2(state.cvv2);

        // cardInfoSaved({ month: getListMonth().indexOf("" + state.expireMonth) , year: })
      }

      setAutofillCardInputs(state.autofillCardInputs);
      setFormData(state);
      requestGetRemainedTime();
    }

    // }
  }, [state]);

  const handleClickEnter = (e) => {
    if (e.key === "Enter") {
      handleBtnSubmit();
    }
  };
  return (
    <Box
      //  height="65vh"
      style={
        { marginTop: "10px", marginBottom: 30 }
      }
    >

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        spacing={0}
        className={`${classes.boxMain} ${(loading || isResult.result) && "blurBackground"
          }`}
        style={{ position: "relative" }}
      >
        {loading && (
          <DialogLoading2 isResult={loading} setEndProcess={setEndProcess} startProcess={0} />
        )}

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          xs={12}
          className={classes.layoutTitle2}
        >
          <Grid item className={classes.backgroundTimerOne}>
            {timeForm && (
              <Timer
                expiryTimestamp={timeForm}
                setSendMsgCode={(result_) => {
                  setIsResult({
                    ...isResult,
                    result: true,
                    msg: "زمان پرداخت شما به اتمام رسید",
                    isSuccess: 0,
                    endTimeFrom: true,
                  });
                }}
              />
            )}
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justify="space-around"
          alignItems="center"
          className={`${classes.headerLayout} un-selected `}
        >
          <Grid item xs={3} className={classes.headerText}>
            درگاه
          </Grid>
          <Grid item xs={3} className={classes.headerText}>
            مبلغ
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justify="space-evenly"
          alignItems="center"
        >
          <Grid container direction="column" justify="center" alignContent="center" alignItems="center"
            style={{ flex: 1 }}>
            {" "}
            <Avatar
              alt="logo bank"
              // src={value.bankId!=-1 ? urlIcon:null}
              src={`./gatewaysLogo/${formData?.gatewayId}.png`}
              className={classes.small}
              style={{
                marginTop: 5,
              }}
            >
              ?
            </Avatar>
            <Grid
              item
              xs={4}
              style={{
                textAlign: "center",
                color: "#000000",
                fontSize: "14px",
                maxWidth: "100%",
                marginTop: 5,
              }}
            >
              {window.sessionStorage.getItem("gatewayName")}
            </Grid>
          </Grid>
          <Grid container justify="center" xs={1}>
            <div className={classes.lineVertical} />
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ flex: 1 }}
          >
            <Grid
              item
              xs={4}
              style={{
                color: "#18CE9B",
                fontSize: "20px",
                marginTop: "20px",
                maxWidth: "100%",
              }}
            >
              {convertNumberToPice(formData?.amount)}
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                textAlign: "center",
                color: "#18CE9B",
                fontSize: "14px",
                maxWidth: "100%",
              }}
            >
              تومان
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            border: "1px dashed #c2c2c2",
            marginBottom: 15,
            marginTop: 5,
          }}
        ></Grid>

        <Grid
          container
          xs={12}
          direction="row-reverse"
          justify="center"
          spacing={0}
        >
          {sessionStorage.getItem("nationalCode") && (
            <InputNationalCode
              setNationalCode={() => { }}
              setIsResult={() => {
                setIsResult({ ...isResult, result: true, msg: 'کدملی را درست وارد کنید', isSuccess: 0 })
              }}
            // toNext={toNext}
            //  setToNext={setToNext}
            //  enterClick={handleButtonSubmit}
            />
          )}

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            justifyContent="center"
            xs={10}
            className="un-selected"
            style={{
              marginLeft: 15,
              marginRight: 20,
              textAlign: "end",
              marginBottom: "10px",
              fontSize: "14px",
            }}
          >
            <Grid item style={{ color: "#000" }}>
              &nbsp; شماره کارت&nbsp;
            </Grid>
            <div
              style={{
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                background: "rgba(0,0,0,0.5)",
                marginTop: "6px",
              }}
            ></div>
          </Grid>

          <Grid
            container
            direction="row"
            xs={10}
            justify="space-around"
            alignContent="center"
            alignItems="center"
            style={{ marginBottom: 10, marginTop: 0 }}
          >
            <Grid
              container
              xs={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              {formData?.userCardNumber && (
                <Avatar
                  alt="logo bank"
                  src={`./banksLogo/${getLogo(
                    `${formData?.userCardNumber}`
                  )}.png`}
                  className={classes.smallIcon}
                />
              )}
            </Grid>

            {formData?.userCardNumber &&
              getArrayNumberCard(formData.userCardNumber)?.map(
                (value, index) => {
                  return (
                    <Grid
                      item
                      className="layoutInputDiable paddingLayout un-selected"
                      justify="center"
                      style={{ textAlign: "center" }}
                    >
                      {" "}
                      {setNumberCard(value, index)}
                    </Grid>
                  );
                }
              )}
          </Grid>

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            justifyContent="center"
            className="un-selected"
            xs={10}
            style={{
              marginLeft: 15,
              marginRight: 20,
              textAlign: "end",
              marginBottom: "10px",
              fontSize: "14px",
              marginTop: "5px",
            }}
          >
            <Grid
              item
              className="fontDisable"
              style={{ color: "#000", fontWeight: "bold" }}
            >
              CVV2&nbsp;
            </Grid>
            <div
              style={{
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                background: "rgba(0,0,0,0.5)",
                marginTop: "6px",
              }}
            ></div>
          </Grid>

          <Grid
            item
            xs={10}
            className="layoutInput"
            style={{ marginBottom: 10, marginTop: 0 }}
          >
            <input
              className="inputCard2"
              type="tel"
              placeholder=" شماره شناسایی دوم"
              value={cvv2}
              maxLength={4}
              tabIndex={1}
              onChange={handleChangeCVV2}
              onKeyDown={handleKeyDownCVV2}
            />
          </Grid>

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            justifyContent="center"
            className="un-selected"
            xs={10}
            style={{
              marginLeft: 15,
              marginRight: 20,
              textAlign: "end",
              marginBottom: "5px",
              fontSize: "14px",
              marginTop: "5px",
            }}
          >
            <Grid item> &nbsp; تاریخ انقضاء &nbsp;</Grid>
            <div
              style={{
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                background: "rgba(0,0,0,0.5)",
                marginTop: "9px",
              }}
            ></div>
          </Grid>

          <Grid
            container
            xs={12}
            direction="row-reverse"
            justifyContent="center"
            alignItems="center"
            justify="center"
            style={{ marginTop: 0 }}
          >
            <Grid item xs={5} className="layoutInput spaceRight">
              <FormControl
                className={classes.inputAmount}
                size="medium"
                fullWidth
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="select_month"
                  value={month}
                  onChange={handleChangeMonth}
                  MenuProps={MenuProps}
                  className={`${classes.inputAmount} `}
                  native
                  fullWidth
                >
                  <option
                    className={`${classes.menuItem}  `}
                    value={-1}
                    disabled
                    style={{ justifyContent: "flex-end" }}
                    id="item_month"
                    ref={myRefMonth}
                  >
                    ماه
                  </option>
                  {getListMonth().map((value, index) => {
                    return (
                      <option value={index} className={classes.menuItem}>
                        {value}
                      </option>
                    );
                  })}
                </Select>
                {month != -1 && (
                  <Grid
                    item
                    className={`${classes.titleSelectExpare} ${classes.selectBoxMonthTitle}`}
                  >
                    ماه
                  </Grid>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={5} className="layoutInput spaceLeft">
              <FormControl
                className={classes.inputAmount}
                size="medium"
                fullWidth
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="select-year"
                  value={year}
                  onChange={handleChangeYear}
                  MenuProps={MenuProps}
                  className={classes.inputAmount}
                  native
                  fullWidth
                >
                  <option
                    className={classes.menuItem}
                    value={-1}
                    disabled
                    style={{ justifyContent: "flex-end" }}
                  >
                    سال
                  </option>
                  {listYear.map((value, index) => {
                    return (
                      <option value={index} className={classes.menuItem}>
                        {value}
                      </option>
                    );
                  })}
                </Select>
                {year != -1 && (
                  <Grid
                    item
                    className={`${classes.titleSelectExpare} ${classes.selectBoxMonthTitle}`}
                  >
                    سال
                  </Grid>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            className="un-selected"
            justifyContent="center"
            xs={10}
            style={{
              marginLeft: "15px",
              marginRight: "20px",
              textAlign: "end",
              marginBottom: "5px",
              fontSize: "14px",
              marginTop: "15px",
            }}
          >
            <Grid item> &nbsp;رمز دوم  &nbsp;</Grid>
            <div
              style={{
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                background: "rgba(0,0,0,0.5)",
                marginTop: "9px",
              }}
            ></div>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignContent="center"
            xs={10}
            className="layoutInput"
            style={{ marginTop: 0, marginBottom: 10 }}
          >
            {sendMsg ? (
              <Grid
                container
                direction="row"
                xs={6}
                justify="space-evenly"
                justifyContent="center"
                alignContent="center"
                alignItems="center"
              >
                <Grid item xs={2} className={classes.colorTimer}>
                  <Timer expiryTimestamp={time} setSendMsgCode={setSendMsg} />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ color: "#2465FF", textAlign: "left" }}
                >
                  <MailOutlineIcon
                    className="iconSend"
                    style={{ marginTop: 7 }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                xs={6}
                justify="center"
                alignContent="center"
                className={classes.layoutSubmitCode}
              >
                <Button
                  size="small"
                  type="submit"
                  variant="text"
                  color="primary"
                  onClick={handleSendMsg}
                  className={classes.submitPassword}
                  endIcon={<MailOutlineIcon />}
                  fullWidth
                >
                  دریافت رمز دوم &nbsp;
                </Button>
              </Grid>
            )}
            <Grid item xs={4}>
              <input
                className="inputCard2"
                type="tel"
                onChange={handleChangePin2}
                value={pin2}
                placeholder="رمز دوم"
                maxLength={12}
                tabIndex={4}

                ref={myRef}
                style={{ background: "#000 !important", textAlign: "center" }}
                onKeyDown={handleClickEnter}
              />
            </Grid>
          </Grid>
          {(formData?.showSaveCardDataOption || showSaveCardDataOption) && (
            <Grid
              container
              xs={10}
              justify="flex-end"
              style={{ paddingLeft: "15px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={autofillCardInputs}
                    onChange={handleChangeAutoFill}
                    name="checkedA"
                  />
                }
                label="ذخیره اطلاعات کارت"
                className={classes.checkBoxRemember}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justify="space-evenly"
          // justify="center"
          alignItems="center"
          justifyContent="space-evenly"
          className={classes.layoutButtones}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          <Grid item xs={4}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
              className={`${classes.submit}`}
              style={{ background: "#d32f2f " }}
              onClick={handleBtnCancel}
            >
              انصراف
            </Button>
          </Grid>
          <div style={{ flex: 1 }}></div>
          <Grid item xs={7}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
              className={classes.submit}
              onClick={handleBtnSubmit}
            >
              پرداخت
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <DialogCode
        isResult={isShowCodeDialog.open}
        handleCloseDialog={handleCloseDialogCode}
        handleBackDialog={handleBackDialog}
        handleSubmitDialogCode={handleSubmitDialogCode}
        confirmCodeImageId={confirmCodeImageId}

      />

      {isResult.isSuccess == 2 ? (
        <DialogSuccess
          isResult={isResult}
          handleCloseDialog={handleCloseDialogSuccess}
        />
      ) : isResult.isSuccess == 1 ? (
        <DialogWarring
          isResult={isResult}
          handleCloseDialog={handleCloseDialogWarring}
          handleCloseAndYesDialog={handleCloseAndBackDialogWarring}
          title={'لغو تراکنش'}
        />
      ) : (
        <DialogError
          isResult={isResult}
          handleCloseDialog={handleCloseDialogError}
          setNavigate={setNavigate}
        />
      )}
    </Box>
  );
};

export default LastStepPage;
