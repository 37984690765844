import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
} from "@material-ui/core";
import { usePaymentInputs } from "react-payment-inputs";

import Api from "../../../../api/Api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  editPhoneText: {
    fontSize: "13px",
    color: "#0087c9",
    // color: "#039BE5", 
  },
  editPhoneText2: {
    fontSize: "13px",
    color: "#4caf50",


  },
}));

const InputCard = ({ setNationalCode, toNext, setToNext, enterClick, setIsResult }) => {
  const classes = useStyles();

  const [input1, setInput1] = useState(sessionStorage.getItem('nationalCode'));
  const [isEdit, setIsEdit] = useState(true);
  const myRef = React.useRef(null);
  const myRef2 = React.useRef(null);
  const myRef3 = React.useRef(null);
  const myRef5 = React.useRef(null);




  const handleChangeCardNumber1 = (e) => {

    let numberPhone = e.target.value
    setInput1(numberPhone);
    if (numberPhone.length <= 15) {

      setNationalCode(numberPhone);
    }




    // if (e.target.value.length == 11) {
    //   setInput1(e.target.value);
    //   setEnableBtn(true);

    // } else if (e.target.value.length < 11) {
    //   setInput1(e.target.value);
    //   setEnableBtn(false);
    // }
  };

  const handleClickEnter = (e) => {

    if (e.key === 'Enter') {
      enterClick()
    }
  }
  const handleChangeCardNumber = (ref, changeState, input) => (e) => {
    if (e.target.validity.valid) {
      changeState(e.target.value);
      if (input.length > 2) ref.current.focus();
    }
  };

  const handleEditPhoneBtn = (e) => {
    //  setToNext(false);

    setIsEdit(false)
    setTimeout(() => {
      myRef.current.focus();
    }, 200);

  };
  const handleClosePhoneBtn = (e) => {

    if (input1 && input1.length <= 15) {
      window.sessionStorage.setItem("nationalCode", input1);
      setIsEdit(true)
    } else {

      setIsResult()
    }





  };
  return (
    <Grid
      container
      xs={12}
      direction="row-reverse"
      justify="center"
      alignItems="center"
      spacing={0}
    >
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignContent="center"
        justifyContent="center"
        xs={9}
        style={{
          marginLeft: 15,
          textAlign: "end",
          fontSize: "14px",
          marginTop: 0,
          marginBottom: 10
        }}
      >
        <Grid item> &nbsp; کد ملی &nbsp;</Grid>
        <div
          style={{
            borderRadius: "50%",
            width: "4px",
            height: "4px",
            background: "rgba(0,0,0,0.5)",
            marginTop: "9px",
          }}
        ></div>
      </Grid>



      <Grid
        container
        xs={10}
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={!isEdit ? "layoutInput" : "layoutInputDiable"}

        spacing={0}
        style={{ margin: 0, marginTop: 12, marginBottom: 15 }}
      >
        <Grid container xs={4} justify="space-around" alignContent="center" direction="row"  >
          {isEdit ? (
            <>
              <Grid item>
                <Button
                  size="small"
                  type="submit"
                  variant="text"
                  fullWidth
                  className={classes.editPhoneText}
                  color="primary"
                  onClick={handleEditPhoneBtn}
                >
                  اصلاح کدملی
                </Button>
              </Grid>

            </>

          ) :
            (
              <>
                <Grid item >
                  <Button
                    size="small"
                    type="submit"
                    variant="text"
                    fullWidth
                    className={classes.editPhoneText2}
                    color="primary"
                    onClick={handleClosePhoneBtn}
                  >
                    ثبت اصلاح
                  </Button>
                </Grid>

              </>

            )}


        </Grid>
        {<Grid item xs={1}>
          <div className="line"></div>
        </Grid>}
        <Grid item xs={7}>
          <input
            className={`${"inputCard holder"} ${isEdit && "inputCardEdit"}`}
            type="tel"
            pattern="[0-9]*"
            onInput={handleChangeCardNumber1}
            value={input1}
            maxLength={11}
            placeholder={" کدملی    را وارد کنید "}
            disabled={isEdit}
            ref={myRef}
            onKeyDown={handleClickEnter}

          />
        </Grid>

      </Grid>
    </Grid>
  );
};

export default InputCard;
