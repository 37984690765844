

export const getNumberCardFromatter = (number) => {



    return number.replace(/\W/gi, "").replace(/(.{4})/g, "$1-").slice(0, -1);
};



const search = (nameKey) => {
    let listBank = window.sessionStorage.getItem("banksCardPrefixes")
    if (listBank) {
        let list = JSON.parse(listBank)
        for (var i = 0; i < list.length; i++) {
            if (list[i].cardPrefix == nameKey) {

                return list[i].bankId;
            }

        }
    }


}
export const getLogo = (numberCard) => {
    let edit = numberCard.replace(/\s/g, '')

    if (edit.length > 6)
        edit = edit.substring(0, 6)
    if (edit.length == 6)
        return search(edit)

    return null
}