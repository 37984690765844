import {
    SHOW_ERROR_DIALOG, SHOW_WARRING_DIALOG
} from '../actionTypes'

const infoState = {
    showErrorDialog: -1,
    showWarringDialog: -1,
    dataDialog: null,
};

const TableBtnReducer = (state = infoState, action) => {


    switch (action.type) {
        case SHOW_ERROR_DIALOG: {
            const {
                showErrorDialog, dataDialog } = action.payload;
            return {
                ...state,
                showErrorDialog: showErrorDialog,
                dataDialog: dataDialog,
            };
        }

        case SHOW_WARRING_DIALOG: {
            const {
                showWarringDialog, dataDialog } = action.payload;
            return {
                ...state,
                showWarringDialog: showWarringDialog,
                dataDialog: dataDialog,
            };
        }

        default: {
            return state;
        }
    }

}

export default TableBtnReducer;