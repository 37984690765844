import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
} from "@material-ui/core";
import {
  Box,
  Typography,
  Checkbox,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CachedIcon from '@material-ui/icons/Cached';
import { Player } from "@lottiefiles/react-lottie-player";
import { connect } from "react-redux";
import { getChangeProcessC2cReducer } from "../../../../redux/selectors"; 
import DoubleBubble from './DoubleBubble/DoubleBubble';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={{ enter: 450, exit: 450, }} />;
});

const useStyles = makeStyles((theme) => ({
  dialogLayout: {

    "& .MuiDialog-paper": {
      overflowY: "visible !important",
    },
    // "& div > div": {
    //   overflowY: "visible !important",
    // },
    // "& .MuiDialog-paperScrollPaper": {
    //   overflowY: "visible !important",
    // },

    background: "#0009",
  },
  circleIcon: {
    borderRadius: "50%",
    width: "80px",
    height: "80px",
    // border:"3px solid",
    position: "absolute",
    zIndex: "150",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    border: "5px solid #ffffff",
    background: "#4CAF50",
    left: "50%",
    transform: "translate(-50%,-50%)",


    // position:"relative",
    // marginTop: "-30px",
  },
  successDialog: {
    borderLeft: "5px solid #4CAF50",
    marginTop: "10px",
    background: "#4CAF5040",
    padding: "4px",
    textAlign: "left",
    paddingLeft: "5px",
    fontSize: "14px",
    marginTop: "10px",
    borderRadius: "8px",
  },
  iconDialog: {
    fontSize: "52px",
    color: "white",
  },
  titleDialog: {
    fontSize: "18px",
    color: "#3b3b3b",
    marginTop: "15px",
    textAlign: "center",
  },
  titleDialog2: {
    fontSize: "14px",
    color: "#3b3b3b",
    textAlign: "center",
  },
  textDialog: {
    textAlign: "center",
    marginTop: "10px",
    width: "100%",
    color: "#757575",
    marginLeft: "15px",
    marginRight: "15px",
    maxWidth: "300px",
    paddingRight: "15px",
    paddingLeft: "15px",
    alignContent: "center",
    alignItems: "center",
    justify: "center",
    justifyContent: "center",
    justifySelf: "center",

  },
  layoutButtonDialog: {
    marginTop: "20px",
    color: "#4d4d4d",
    minWidth: "250px",
  },
  circle: {
    width: "60px !important",
    height: "60px !important",
    color: "#4CAF50",
  },
  layout: {
    //  position:"absolute",
    //  left:"40%",
    //  top:"80%"
    marginBottom: "20px",
    marginTop: "10px",
  },
  textColor: {
    color: "#8b8791",
    fontSize: "16px",
  },
  textColor2: {
    color: "#4CAF50",
    fontSize: "14px",
  },
}));
let prog = 0
const DialogLoading2 = (props) => {
  const classes = useStyles();
  const { isResult, handleCloseDialog, setEndProcess, startProcess } = props;
  const [progress, setProgress] = React.useState(0);

  // useEffect(() => {
  //   if (progress == 100) {
  //     setEndProcess(true)
  //   } else {
  //     setInterval(() => {
  //       setProgress((v) => v + 1)
  //     }, 800);

  //   }


  // }, [startProcess]);

  return (
    <Dialog
      open={isResult}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.dialogLayout}
    >

      <div style={{ height: "60px", justifyContent: "center", alignItems: "center", position: "relative" }}>
        <Grid
          container
          className={classes.circleIcon}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <CachedIcon className={classes.iconDialog} />
        </Grid>
      </div>

      <Grid
        container
        direction="column"
        justify="center"
        alignContent="center"
        justifyContent="center"
        className="un-selected"
        style={{ color: "dark", minWidth: "250px" }}
      >
        <Grid item className={classes.titleDialog}>
          {" "}
          لطفا منتظر بمانید
        </Grid>
        <Grid item className={classes.titleDialog2}>
          {" "}
          عملیات ممکن است کمی طول بکشد
        </Grid>
        <div className={`${classes.textDialog}  un-selected`}>
          {isResult.msg}
        </div>
      </Grid>

      <Grid container justify="center" alignItems="center" justifyContent="center" direction="column" style={{ textAlign: "center", }}>
        <DoubleBubble />


        <Grid item className={`${classes.titleDialog2} fade_2`} style={{ marginBottom: 10, color: "red" }} >
          {" "}
          تا زمان اتمام عملیات از صفحه خارج نشوید
        </Grid>
        {/* <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          className={classes.textColor}
        >{`${Math.round(progress)}%`}</Typography> */}
        {/* <Box position="relative" display="inline-flex" className={classes.layout} >

          <CircularProgress variant="determinate" value={progress} className={classes.circle} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              className={classes.textColor}
            >{`${Math.round(progress)}%`}</Typography>
          </Box>

        </Box> */}
      </Grid>


    </Dialog>
  );
};
DialogLoading2.propTypes = {
  isResult: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  setEndProcess: PropTypes.func.isRequired,
};

export default DialogLoading2;