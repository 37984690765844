import React from "react";
import { Grid, Avatar, Button } from "@material-ui/core";
import Chip from "../../../assets/images/chip.png";
import { getArrayNumberCard } from "../../../tools/Tools";
import { getLogo } from "../cpg/addCardPage/ListBank";

const CardBank = ({ cardNumber, nameCard, nameBank }) => {
  const [isShowCopy, setIsShowCopy] = React.useState(false)
  const myRef = React.useRef(null)

  return (
    <Grid
      container
      xs={12}
      direction="row"

      alignItems="center"
      justifyContent="center"
      style={{ marginBottom: "10px" }}
    >

      <Grid
        container
        direction="row"
        justify="center"
        xs={11}
        className="card_bank_layout"
      >
        <div className="card_bank_backgroud">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%", borderRadius: "10px" }}
          >
            <path
              d="M-13.60,134.70 C279.76,158.38 204.07,0.50 510.20,25.16 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: "none", fill: "#00000015" }}
            ></path>
          </svg>
        </div>
        <div className="card_bank_backgroud car2">
          <svg
            viewBox="0 0 500 150"
            preserveAspectRatio="none"
            style={{ height: "100%", width: "100%", borderRadius: "10px" }}
          >
            <path
              d="M-13.60,134.70 C279.76,158.38 204.07,0.50 510.20,25.16 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: "none", fill: "#0001" }}
            ></path>
          </svg>
        </div>
        <img src={Chip} className="card_bank_chip un-selected" />
        <input type="text" id="a" className="card_number" ref={myRef} value={cardNumber &&
          getArrayNumberCard(cardNumber).map((value, index) => {
            return `${value}${index != 3 ? "   " : ""}`;
          }).toString().replace(/,/g, "")}


        >

        </input>
        <img
          alt="logo bank"
          src={`./banksLogo/${getLogo(`${cardNumber}`)}.png`}
          className="card_bank_logo un-selected"
        />
        <div className="card_bank_title  un-selected">کارت جهت واریز</div>
        <div className="card_bank_name_bank  un-selected" >{nameBank}</div>
        <div className="card_bank_name">

          {nameCard}</div>
      </Grid>


    </Grid>
  );
};
export default CardBank;
