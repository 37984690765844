import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import anim from "../../../../assets/anim/noCard.json";
import anim2 from "../../../../assets/anim/ku7pysap.mp4";

import { useLocation } from "react-router-dom";

import styled, { keyframes } from "styled-components";
import { flipInX } from "react-animations";
import { useStyles } from "./style";
import { setTheme, containsNumber, replaceAllEnter } from "../../../../tools/Tools";
const clickBtn = () => { };
const PayNotPage = ({ setLogo, setNavigate }) => {
  const classes = useStyles();
  const { state } = useLocation();
  const [infoSite, setInfoSite] = useState({});
  const [click, setClick] = useState(false);
  React.useEffect(() => {

    setTheme(setLogo);
  }, []);

  useEffect(() => {
    if (!window.sessionStorage.getItem("PayNot"))
      setNavigate("loading")
    const info = window.sessionStorage.getItem("webSiteInfo");
    if (info) setInfoSite(JSON.parse(info));
  }, []);
  return (

    <Box style={{ marginTop: "40px" }}>
      <Grid container spacing={0} className={classes.boxMain}>

        <Grid container direction="row" justify="flex-start" xs={8}>
          {JSON.parse(window.sessionStorage.getItem("isSelectMethodAddCard")) && (
            <Button
              size="small"
              type="submit"
              variant="text"
              color="primary"
              onClick={() => {

                setNavigate("selectMethod")

              }}
              className={classes.backBtn}
              startIcon={<ArrowBackIosIcon />}
            >
              بازگشت
            </Button>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            marginTop: "23px",
            fontFamily: "IRANSans",
            marginRight: 10,
            marginLeft: 10,

          }}
        >
          {window.sessionStorage
            .getItem("undefinedCardPaymentErrorMessage")
            .split("\n")
            .map(function (item, idx) {
              return (
                <span key={idx}>
                  {replaceAllEnter(item)}
                  <br />
                </span>
              );
            })}
        </Grid>
        <Grid container
          justify="center"
          alignItems="center" xs={12} >
          {state ?

            <video src={anim2} width="300" height="300" autoPlay loop>
            </video>
            :

            <Player
              autoplay
              loop
              src={anim}
              style={{ height: "300px", width: "300px" }}
            />}

        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            startIcon={<ArrowBackIosIcon />}
          >
            <a
              href={infoSite?.websiteAddress}
              style={{ color: "white", textDecoration: "none" }}
            >
              بازگشت به{" "}
              {containsNumber(infoSite?.websiteName) ? (
                <span className="unSetFont"> {infoSite?.websiteName}</span>
              ) : (
                infoSite.websiteName
              )}
            </a>
          </Button>
        </Grid>
      </Grid>
    </Box>

  );
};
export default PayNotPage;
