import React from "react";
import { useTimer } from "react-timer-hook";

const Timer = ({ expiryTimestamp, setSendMsgCode }) => {
 
  const {
    seconds,
    minutes,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setSendMsgCode(false);
    },
  });

  return (
    <div style={{ textAlign: "center" }}>
      <span>{minutes<10?`0${minutes}`:minutes}</span>:<span>{seconds<10?`0${seconds}`:seconds}</span>
    </div>
  );
};

export default Timer;
