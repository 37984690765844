import React, { Component, useState } from "react";
import {
  Grid,
  Button,
  Box,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  Typography,
  ListItemIcon,
  Avatar,
} from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { getLogo, getNumberCardFromatter } from "../../cpg/addCardPage/ListBank";
import Slide from "@material-ui/core/Slide";
import { useStyles } from "./style";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ContactSupportIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {
  setTheme,
  convertNumberToPice,
  resulation,
  setNumberCard,
} from "../../../../tools/Tools";
import Api from "../../../../api/Api";
import ReactDOM from "react-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import WarningIcon from "@material-ui/icons/Warning";
import DialogError from "../../cpg/dialogs/DialogError";
import LoadingProcess from "../../cpg/LoadingProcess";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { showErrorRequest, replaceAllSpace, convertNumbers2EnglishString } from './../../../../tools/Tools';
const listForSendToServer = {
  amount: -1,
  cardNumber: "",
};


let setIsTheme = false;



const InputAmount = ({ expanded, setExpanded, isValueDefult, valueDefult }) => {
  const [amount, setAmount] = React.useState("");
  const handleChangeAmount = (e) => {
    let value = replaceAllSpace(e.target.value)
    if (!(/[a-zA-Z]/).test(value)) {
      value = convertNumbers2EnglishString(value);
      sessionStorage.setItem("showExpanded", true);
      listForSendToServer.amount = value.replace(/,/g, '');
      setAmount(convertNumberToPice(value));
      if (!expanded) setExpanded(true);

    }

  };


  React.useEffect(() => {
    if (isValueDefult) {
      sessionStorage.setItem("showExpanded", true);
      listForSendToServer.amount = valueDefult
      setAmount(convertNumberToPice(valueDefult))
      if (!expanded) setExpanded(true);
    }


  }, [isValueDefult])


  return (
    <div style={{ position: "relative" }}>
      <input
        className="inputCard2"
        type="tel"
        placeholder="مبلغ را وارد کنید"
        value={amount}
        maxLength={13}
        tabIndex={1}
        disabled={isValueDefult}
        onChange={handleChangeAmount}
        style={{ width: "100%" }}
      />
      {amount != "" && <span className="monyInput">تومان</span>}
    </div>
  );
};


const HomePage = ({ webSiteName, setLogo, setNavigate }) => {
  const classes = useStyles();
  const [cardNumber, setCardNumber] = useState("-1");
  const [loading, setloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);

  const [isResult, setIsResult] = useState({
    result: false,
    msg: "شماره کارت وارد نشده است",
    isSuccess: false,
  });

  const [msg, setMsg] = useState("خطا از سمت سرور ");
  const [amount, setAmount] = React.useState("5");
  const [listInfo, setListInfo] = React.useState();
  const myRef = React.useRef(null);

  const handleOpenAddCard = () => {


    if (!listInfo?.isPendingCardExists) {
      window.sessionStorage.setItem("addCard", true);
      window.sessionStorage.setItem("btn_back_addCard", true);
      window.sessionStorage.setItem("addCard_StepStep", true);

      setNavigate("addCard");
    } else {
      setIsResult({
        ...isResult,
        result: true,
        msg: listInfo?.pendingCardErrorMessage,
        isSuccess: 0,
      });
    }




    // sessionStorage.setItem("backToHome", true);
    //setOpenDialogAddCard(true);
  };

  const handleChangeAmount = (event) => {
    sessionStorage.setItem("showExpanded", true);
    listForSendToServer.amount = event.target.value;

    setAmount(event.target.value);
    if (!expanded) setExpanded(true);
    else {
      window.scrollTo(0, 500);
    }
  };


  // const handleChangeAmount = (event) => {
  //   sessionStorage.setItem("showExpanded", true);
  //   listForSendToServer.amount = event.target.value;

  //   if (!listInfo?.isPriceListExists === true)
  //     setAmount(convertNumberToPice(event.target.value));
  //   else setAmount(event.target.value);
  //   if (!expanded) setExpanded(true);
  //   else {
  //     window.scrollTo(0, 500);
  //   }
  // };

  const handleChangeCard = (event) => {
    if (expanded2) window.scrollTo(0, 500);
    else {
      setExpanded2(true);
      setTimeout(() => {
        window.scrollTo(0, 500);
      }, 500);
    }

    listForSendToServer.cardNumber =
      listInfo.listCardBank[event.target.value]?.cardNumber;

    setCardNumber(event.target.value);


  };

  const clickBtn = () => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
      if (listForSendToServer.amount != -1) {
        if (cardNumber != -1) {
          if (listForSendToServer.cardNumber.length > 3) {

            // window.history.pushState(null, null, window.location.href);
            // let address = window.location.href.replace("home", "setPhone");
            // window.history.pushState(null, null, address);

            requestStartPayment()

          } else
            setIsResult({
              ...isResult,
              result: true,
              msg: "شماره کارت را مشخص کنید",
            });
        } else
          setIsResult({ ...isResult, result: true, msg: "شماره کارت را مشخص کنید" });
      } else
        setIsResult({ ...isResult, result: true, msg: "مبلغ را مشخص کنید" });
    }, 650);
  };
  const handleCloseDialog = (event, reason) => {


    setIsResult({ ...isResult, result: false });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      },
    },
  };

  const requestStartPayment = async () => {
    try {


      const { status, data } = await Api.startPaymentIPG(listForSendToServer);
      if (status === 200) {
        if (data.success) {

          if (!data.isDirect) {
            window.localStorage.clear();
            window.sessionStorage.clear();
            window.open(data.redirectUrl, "_self")


          } else {

          }
          // window.sessionStorage.setItem("depositId", data.depositId);
          //  window.sessionStorage.setItem("isLastStepC2C", true);

          //   setNavigate("last-step-c2c", data.FormData)


        } else showError(data.message);
      } else {
        showError(showErrorRequest(status));
      }


    } catch (error) {
      showError(showErrorRequest(0));
    }
  };

  const showError = (msg) => {
    setloading(false);
    setIsResult({
      ...isResult,
      result: true,
      msg: msg,
      isSuccess: 0,
    });
  };
  React.useEffect(() => {


    const isPendingCardExists = JSON.parse(
      window.sessionStorage.getItem("isPendingCardExists")
    );
    const pendingCardErrorMessage = window.sessionStorage.getItem("pendingCardErrorMessage")

    const isPriceListExists = JSON.parse(
      window.sessionStorage.getItem("isPriceListExists")
    );


    const isDefaultAmountExists = JSON.parse(
      window.sessionStorage.getItem("isDefaultAmountExists")
    );

    const listCardBank = JSON.parse(window.sessionStorage.getItem("userCards"));

    const defaultAmount = window.sessionStorage.getItem("defaultAmount")

    if (!isPriceListExists) setAmount("");

    if (isPriceListExists) {
      const listPice = JSON.parse(window.sessionStorage.getItem("priceList"));
      setListInfo({
        listPice: listPice,
        isPriceListExists: isPriceListExists,
        listCardBank: listCardBank,
        isDefaultAmountExists: isDefaultAmountExists,
        defaultAmount: defaultAmount,
        isPendingCardExists: isPendingCardExists,
        pendingCardErrorMessage: pendingCardErrorMessage,

      });
    } else {
      const minimumDepositAmount = JSON.parse(window.sessionStorage.getItem("minimumDepositAmount"));
      const maximumDepositAmount = JSON.parse(window.sessionStorage.getItem("maximumDepositAmount"));
      setListInfo({
        isPriceListExists: isPriceListExists,
        listCardBank: listCardBank,
        minimumDepositAmount: minimumDepositAmount,
        maximumDepositAmount: maximumDepositAmount,
        isDefaultAmountExists: isDefaultAmountExists,
        defaultAmount: defaultAmount,
        isPendingCardExists: isPendingCardExists,
        pendingCardErrorMessage: pendingCardErrorMessage,
      });
    }

  }, []);

  React.useEffect(() => {
    if (listForSendToServer.amount != -1) {

      setAmount((listForSendToServer.amount));
      let listCard = JSON.parse(window.sessionStorage.getItem("userCards"));
      let index = listCard.findIndex(
        (x) => x.cardNumber == listForSendToServer.cardNumber
      );

      setExpanded(true);
      setCardNumber(index);
    }
  }, []);

  React.useEffect(() => {
    if (!JSON.parse(window.sessionStorage.getItem("ishomeIPG")))
      setNavigate("loading")
    if (JSON.parse(window.sessionStorage.getItem("ishomeIPG")) && !setIsTheme) {
      setIsTheme = true;
      document.title = ' واریز'
      setTheme(setLogo);
    }
  }, []);
  // style={resulation<=2000?{marginTop: "20px",marginBottom:"30px"}:{marginTop: "20px",marginBottom:"30px"} }
  return (


    <Box style={{ marginTop: "15px", marginBottom: 30 }}>
      <Grid
        container
        direction="row"
        justify="center"
        spacing={0}
        className={`${classes.boxMain} ${(loading || isResult.result) && "blurBackground"
          }`}
        //  style={{filter:"blur(3px)"}}
        style={{ position: "relative" }}
      >
        <LoadingProcess loading={loading} />

        <Grid item xs={7}>
          {JSON.parse(window.sessionStorage.getItem("isSelectMethod")) ? <Button
            size="small"
            type="submit"
            variant="text"
            color="primary"
            onClick={() => {
              setNavigate("selectMethod", true);

            }}
            className={classes.backBtn}
            startIcon={<ArrowBackIosIcon />}
          >
            بازگشت
          </Button>
            :
            <Button
              size="small"
              type="submit"
              variant="text"
              color="primary"
              onClick={() => {
                // setIsNextStep(true);
              }}
              className={classes.backBtn}
              startIcon={<ArrowBackIosIcon />}
            >
              <a
                href={
                  JSON.parse(window.sessionStorage.getItem("webSiteInfo"))
                    .websiteAddress
                }
                style={{ color: "rgb(57, 57, 57)", textDecoration: "none", direction: "rtl" }}
                target="_self"
              >
                {" "}
                بازگشت به{" "}
                {
                  JSON.parse(window.sessionStorage.getItem("webSiteInfo"))
                    .websiteName
                }{" "}
              </a>
            </Button>

          }
        </Grid>

        <Grid
          item
          className={`${classes.layoutTitle} backgroundTitle un-selected`}
          style={{ marginBottom: 30 }}
          xs={5}
        >
          مشخصات واریز
        </Grid>
        <Grid
          item
          style={{ marginBottom: 20 }}

          xs={10}
        >

          <Button
            size="small"
            type="submit"
            variant="outlined"
            fullWidth
            className={classes.btnHelp}
            color="primary"
            onClick={() => setNavigate("help")}
            endIcon={<ContactSupportIcon />}
            style={{ width: 130 }}
          >
            &nbsp;   راهنمای درگاه &nbsp;
          </Button>

          {JSON.parse(
            window.sessionStorage.getItem("showDisableVPNBox")) && <Grid
              xs={12}
              className={classes.errorDialog}
              style={{ marginTop: 40, marginBottom: 30 }}
            >
              {sessionStorage.getItem("disableVPNBox_Message")}
            </Grid>}
          {/* {JSON.parse(
            window.sessionStorage.getItem("isUserAllowedToAddCard")
          ) ? (
            <Grid item xs={7} style={{ marginBottom: 20 }}>
              <Button
                size="small"
                type="submit"
                variant="outlined"
                fullWidth
                className={classes.btnAddCard}
                color="primary"
                onClick={handleOpenAddCard}
                endIcon={<CreditCardIcon />}
              >
                &nbsp; افزودن کارت جدید &nbsp;
              </Button>
            </Grid>
          ) : (
            
          )} */}


        </Grid>
        {(!listInfo?.isPriceListExists && !listInfo?.isDefaultAmountExists) ? (
          <>
            <Grid
              container
              xs={10}
              direction="row-reverse"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={5} className={classes.titleAmount}>
                {" "}
                حداقل مبلغ واریز
              </Grid>
              <Grid item xs={5} className={classes.titleAmount}>
                حداکثر مبلغ واریز
              </Grid>
            </Grid>

            <Grid
              container
              xs={10}
              direction="row-reverse"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: "10px" }}
            >
              <Grid item xs={5} className={classes.bodyAmount2}>
                {" "}
                {convertNumberToPice(
                  listInfo?.minimumDepositAmount
                )}{" "}
                <span style={{ fontSize: "13px", color: "grey" }}>
                  تومان
                </span>
              </Grid>
              <Grid item xs={5} className={classes.bodyAmount2}>
                {
                  listInfo?.maximumDepositAmount === 0 ?
                    <span style={{ fontSize: "14px" }}>
                      نامحدود
                    </span> : <>
                      {convertNumberToPice(
                        listInfo?.maximumDepositAmount
                      )}{" "}
                      <span style={{ fontSize: "13px", color: "grey" }}>
                        تومان
                      </span>
                    </>
                }

              </Grid>
            </Grid>
          </>
        ) :
          <></>

        }

        {expanded && (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            justifyContent="center"
            xs={10}
            style={{
              marginLeft: 15,
              marginRight: 20,
              textAlign: "end",
              marginBottom: "5px",
              fontSize: "14px",
            }}
          >
            <Grid item> &nbsp;   مبلغ &nbsp;</Grid>
            <div
              style={{
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                background: "rgba(0,0,0,0.5)",
                marginTop: "9px",
              }}
            ></div>
          </Grid>
        )}
        <Grid
          item
          xs={10}
          className="layoutInput"
          style={{ marginTop: 5, marginBottom: 20 }}
        >
          {listInfo?.isPriceListExists && !listInfo?.isDefaultAmountExists ? (
            <FormControl
              className={classes.inputAmount}
              size="medium"
              fullWidth
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={amount}
                onChange={handleChangeAmount}
                MenuProps={MenuProps}
                className={classes.inputAmount}
                style={
                  amount == "5"
                    ? {
                      textAlign: "right",
                      paddingTop: 5,
                      paddingBottom: 5,
                      direction: "rtl",
                    }
                    : {
                      textAlign: "center",
                      paddingTop: 5,
                      paddingBottom: 5,
                      direction: "rtl",
                    }
                }
              //  input={<BootstrapInput />}
              >
                <MenuItem
                  value={5}
                  disabled
                  style={{ justifyContent: "flex-end" }}
                >
                  مبلغ را انتخاب کنید
                </MenuItem>

                {listInfo?.listPice.map((value, index) => {
                  return (
                    <MenuItem
                      value={value}
                      style={{
                        direction: "rtl",
                        justifyContent: "flex-start",
                      }}
                      className={classes.menuItem}
                    >
                      {" "}
                      {convertNumberToPice(value)}{" "}
                      <spna
                        style={{
                          color: "grey",
                          paddingRight: "3px",
                          fontSize: "13px",
                        }}
                      >
                        {" تومان "}
                      </spna>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <InputAmount expanded={expanded} setExpanded={setExpanded} isValueDefult={listInfo?.isDefaultAmountExists} valueDefult={listInfo?.defaultAmount} />
          )}
        </Grid>

        {cardNumber != -1 && (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            justifyContent="center"
            xs={10}
            style={{
              marginLeft: 15,
              marginRight: 20,
              textAlign: "end",
              marginBottom: "5px",
              fontSize: "14px",
            }}
          >
            <Grid item> &nbsp; کارت بانکی &nbsp;</Grid>
            <div
              style={{
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                background: "rgba(0,0,0,0.5)",
                marginTop: "9px",
              }}
            ></div>
          </Grid>
        )}

        <Grid item xs={10} style={{ marginTop: 5 }}>
          <Collapse
            in={expanded || sessionStorage.getItem("showExpanded")}
            timeout="auto"
            unmountOnExit
          >
            <Grid container direction="row" justify="center" spacing={0}>
              <Grid
                item
                xs={12}
                className="layoutInput"
                style={{ marginBottom: 20 }}
              >
                <FormControl
                  className={classes.inputAmount}
                  size="medium"
                  fullWidth
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cardNumber}
                    onChange={handleChangeCard}
                    MenuProps={MenuProps}
                    className={classes.inputAmount}
                  >
                    <MenuItem
                      className={classes.menuItem}
                      value={-1}
                      disabled
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          textAlign: "right",
                        }}
                      >
                        {" "}
                        شماره کارت را انتخاب کنید
                      </div>
                    </MenuItem>
                    {listInfo?.listCardBank.map((value, index) => {

                      let number1 = getNumberCardFromatter(
                        value.cardNumber
                      );


                      let urlIcon = `./banksLogo/${value.bankId}.png`;
                      // return( <MenuItem value={index}>{`${getNumberCardFromatter(value.numberCard)}`}</MenuItem>)
                      return (
                        <MenuItem
                          value={index}
                          className={classes.menuItem}
                        >
                          <Grid
                            container
                            style={{ margin: 0, padding: 0 }}
                            alignItems="center"
                            maxWidth="xs"
                            direction="row"
                            justifyContent="center"
                            justify="flex-end"
                          >
                            <Grid
                              item
                              className={classes.marginMenucardNumber}
                            >
                              {" "}
                              <Typography variant="inherit">
                                {" "}
                                {number1.split("-").map((value, index) => <><span className="lastChildCardNumber">{setNumberCard(value, index)}</span><span className="dash_">{index === 3 ? "" : "-"}</span></>)}

                              </Typography>
                            </Grid>
                            <Grid item>
                              {" "}
                              <Avatar
                                alt="logo bank"
                                // src={value.bankId!=-1 ? urlIcon:null}
                                src={urlIcon}
                                className={classes.small}
                              >
                                ?
                              </Avatar>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {JSON.parse(
                window.sessionStorage.getItem("isUserAllowedToAddCard")
              ) ? (
                <Grid item xs={7} style={{ marginBottom: 20 }}>
                  <Button
                    size="small"
                    type="submit"
                    variant="outlined"
                    fullWidth
                    className={classes.btnAddCard}
                    color="primary"
                    onClick={handleOpenAddCard}
                    endIcon={<CreditCardIcon />}
                  >
                    &nbsp; افزودن کارت جدید &nbsp;
                  </Button>
                </Grid>
              ) : (
                <Grid
                  xs={12}
                  className={classes.warringAddCard}
                  style={{ marginBottom: 20 }}
                >
                  جهت افزودن کارت به پشتیبانی سایت مراجعه نمایید
                </Grid>
              )}



            </Grid>
          </Collapse>
        </Grid>
        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            fullWidth
            color="secondary"
            className={classes.submit}
            onClick={clickBtn}
          >
            <Grid
              container
              xs={11}
              direction="row"
              justify="flex-end"
              alignContent="center"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item xs={6} style={{ textAlign: "center" }}>
                ادامه
              </Grid>
              <Grid
                item
                className="line"
                style={{
                  background: "#fff",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              ></Grid>
              <Grid item xs={3} className={classes.imageSubmit}></Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>

      <DialogError
        isResult={isResult}
        handleCloseDialog={handleCloseDialog}
        setNavigate={setNavigate}
      />
    </Box>

  );
};

export default HomePage;
