import React, { Component, useState } from "react";
import { Grid, Container, Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import anim from "../../../assets/anim/anim_active.json";
 
import styled, { keyframes } from 'styled-components';
import { flipInX } from 'react-animations';


const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    //  alignItems: "center",
    //  alignContent:"center",
  },
  layout: {
    alignContent: "center",
    justifyItems: "center",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    "& span": {
      fontFamily: "IRANSans",
    },
    marginTop: theme.spacing(4),
    bottom: theme.spacing(-2),
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(8),
    borderRadius: theme.spacing(5),
    background:"#4CAF50"
 
  },
  boxMain: {
    boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    background: "#fff",
    WebkitBorderRadius: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: 0,
    margin: 0,
  },

  box: {
    // boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    // background: "red",
    // WebkitBorderRadius: theme.spacing(1),
    // borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  anim: {
    opacity: 1,
    transform: "scale(1)",
    transition: "opacity 800ms,transform 800ms",
  },
}));

const NotActive = ({ webSiteName }) => {
  const classes = useStyles();

  const [msg, setMsg] = useState("خطا از سمت سرور ");

  const bounceAnimation = keyframes`${flipInX}`;

const BouncyDiv = styled.div`
  animation: 1.5s ${bounceAnimation};
`;
  return (
    
   <BouncyDiv>
      <Box height="65vh" >
        <Grid container spacing={0} className={classes.boxMain}>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              marginTop: "20px",
              fontFamily: "IRANSans",
            }}
          >
            {msg}
          </Grid>
          <Grid item xs={12}>
            <Player
              autoplay
              loop
              src={anim}
              style={{ height: "260px", width: "250px" }}
            />
          </Grid>

          <Grid
            container
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submit}
              startIcon={<ArrowBackIosIcon />}
            >
              بازگشت به {webSiteName}
            </Button>
          </Grid>
        </Grid>
      </Box>
      </BouncyDiv>
  );
};
export default NotActive;
