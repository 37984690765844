 
import {
    makeStyles, 
  } from "@material-ui/core/styles"; 


const useStyles = makeStyles((theme) => ({
    paper: {
      display: "flex",
      flexDirection: "column",
      //  alignItems: "center",
      //  alignContent:"center",
    },
    layout: {
      alignContent: "center",
      justifyItems: "center",
      textAlign: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      "& span": {
        fontFamily: "IRANSans",
      },
      marginTop: theme.spacing(4),
      bottom: theme.spacing(-3),
      marginRight: theme.spacing(8),
      marginLeft: theme.spacing(8), 
      borderRadius: "8px",
      background: "#4CAF50",
      color: "white !important",
      boxShadow: '0 4px 8px   rgba(0,0,0,0.4)',
    },
    imageSubmit:{    
      width:"25px",
      height:"25px", 
    },

    backBtn: {
      "& span": {
        fontFamily: "IRANSans",
      },
      borderRadius: theme.spacing(20),
      paddingRight: theme.spacing(0.5),
    },
    boxMain: {
      boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
      background: "#fff",
      WebkitBorderRadius: theme.spacing(1),
      borderRadius: theme.spacing(2),
      padding: 0,
      margin: 0,
    },
  
    box: {
      // boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
      // background: "red",
      // WebkitBorderRadius: theme.spacing(1),
      // borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
    },
    anim: {
      opacity: 1,
      transform: "scale(1)",
      transition: "opacity 800ms,transform 800ms",
    },
    input: {
      marginLeft: theme.spacing(5),
      margin: theme.spacing(3),
    },
    layoutTitle: {
      paddingTop: theme.spacing(1),
      // // background:"linear-gradient(176deg, rgba(144,164,174,1) 0%, rgba(38,50,56,1) 100%)",
      textAlign: "center",
      // justifyContent: "center",
      color: "white",
      // paddingBottom: theme.spacing(1),
      // bottom: theme.spacing(-30),
     // background: "rgba(57, 57, 57,1)",
      paddingLeft: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(2.5),
      borderTopLeftRadius: theme.spacing(0.99),
    },
  }));

export default useStyles