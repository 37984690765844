import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CheckIcon from "@material-ui/icons/Check";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} timeout={{ enter: 450, exit: 450, }} />;
});

const useStyles = makeStyles((theme) => ({
  dialogLayout: {
    "& div>div": {
      overflowY: "visible !important",
    },

    background: "#0009",
  },
  circleIcon: {

    borderRadius: "50%",
    width: "80px",
    height: "80px",
    // border:"3px solid",
    position: "absolute",
    zIndex: "150",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    border: "5px solid #ffffff",
    background: "#4CAF50",
    left: "50%",
    transform: "translate(-50%,-50%)",

  },
  successDialog: {
    borderLeft: "5px solid #4CAF50",
    marginTop: "10px",
    background: "#4CAF5040",
    padding: "4px",
    textAlign: "left",
    paddingLeft: "5px",
    fontSize: "14px",
    marginTop: "10px",
    borderRadius: "8px",
  },
  iconDialog: {
    fontSize: "52px",
    color: "white",
  },
  titleDialog: {
    fontSize: "20px",
    color: "#3b3b3b",
    marginTop: "15px",
    textAlign: "center",
  },
  textDialog: {
    textAlign: "center",
    marginTop: "10px",
    width: "100%",
    color: "#757575",
    marginLeft: "15px",
    marginRight: "15px",
    maxWidth: "300px",
    paddingRight: "15px",
    paddingLeft: "15px",
    alignContent: "center",
    alignItems: "center",
    justify: "center",
    justifyContent: "center",
    justifySelf: "center",

  },
  layoutButtonDialog: {
    marginTop: "20px",
    color: "#4d4d4d",
    minWidth: "250px",
  }
}));
const DialogSuccess = (props) => {
  const classes = useStyles();
  const { isResult, handleCloseDialog } = props;
  return (
    <Dialog
      open={isResult.result}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.dialogLayout}
    >

      <div style={{ height: "60px", justifyContent: "center", alignItems: "center", position: "relative" }}>
        <Grid
          container
          className={classes.circleIcon}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <CheckIcon className={classes.iconDialog} />
        </Grid>
      </div>




      <Grid
        container
        direction="column"
        justify="center"
        alignContent="center"
        justifyContent="center"
        className="un-selected"
        style={{ color: "dark", minWidth: "250px" }}
      >
        <Grid item className={classes.titleDialog}>
          {" "}
          عملیات موفق
        </Grid>
        <div className={`${classes.textDialog}  un-selected`}>
          {isResult.msg}
        </div>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        className={classes.layoutButtonDialog}
      >
        <Button
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          onClick={handleCloseDialog}
        >
          بستن
        </Button>
      </Grid>
    </Dialog>
  );
};
DialogSuccess.propTypes = {
  isResult: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};
export default DialogSuccess;
