import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Button, Box } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Player } from "@lottiefiles/react-lottie-player";
import anim from "../../../../assets/anim/support.json";

import { Navigate, Link } from "react-router-dom";

import { useStyles } from './style'
import { setTheme, containsNumber, replaceAllEnter } from "../../../../tools/Tools";

const MsgAddCardPage = ({ setLogo, setNavigate }) => {
  const classes = useStyles();

  React.useEffect(() => {
    if (!window.sessionStorage.getItem("isMsgAddCard"))
      setNavigate("loading")
    setTheme(setLogo);
  }, []);

  const [infoSite, setInfoSite] = useState({});
  const [click, setClick] = useState(false);
  useEffect(() => {
    const info = window.sessionStorage.getItem("webSiteInfo");
    if (info)
      setInfoSite(JSON.parse(info))

  }, [])
  return (

    <Box style={{ marginTop: "40px" }}>
      <Grid container spacing={0} className={classes.boxMain}>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            marginTop: "23px",
            fontFamily: "IRANSans",
            paddingRight: 30,
            paddingLeft: 30
          }}
        >
          {window.sessionStorage
            .getItem("message")
            .split("\n")
            .map(function (item, idx) {
              return (
                <span key={idx}>
                  {replaceAllEnter(item)}
                  <br />
                </span>
              );
            })}
        </Grid>
        <Grid item xs={12}>
          <Player
            autoplay
            loop
            src={anim}
            style={{ height: "300px", width: "300px" }}
          />
        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            startIcon={<ArrowBackIosIcon />}
          >


            <a href={infoSite?.websiteAddress} style={{ color: "white", textDecoration: "none" }}  >   بازگشت به {infoSite?.websiteName}  </a>
          </Button>
        </Grid>

      </Grid>
    </Box>

  );
};
export default MsgAddCardPage;
