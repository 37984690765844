import React from "react";
import {
  Box,
  Typography,
  Checkbox,
  Avatar,
  CircularProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  circle: {
    width:"60px !important",
    height:"60px !important",
    color:"#3e8ae6",
  },
  layout:{ 
     position:"absolute",
     left:"40%",
     top:"80%"
  },
  textColor:{
    color:"#3e8ae6",
    fontSize:"16px", 
  },
  textColor2:{
    position:"absolute",
    color:"#3e8ae6",
    fontSize:"14px", 
    left:"38%",
    top:"91%"
  }
}));

const LoadingCirclePorcess = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.loading && (
        <div className="process backgroundPostionProcess">

          <Box position="relative" display="inline-flex" className={classes.layout} >
            <CircularProgress variant="determinate" {...props} className={classes.circle} />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                className={classes.textColor}
              >{`${Math.round(props.value)}%`}</Typography>
            </Box>

          </Box>
          <div  className={classes.textColor2}> لطفا منتظر بمانید </div>
        </div>
      )}
    </>
  );
};

LoadingCirclePorcess.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default LoadingCirclePorcess;
