import {
  makeStyles,
} from "@material-ui/core/styles";
import nextImage from '../../../../assets/images/next.png'
import overHomeImage from '../../../../assets/images/bg-overlay-home1.png'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    //  alignItems: "center",
    //  alignContent:"center",
  },
  layoutTimer: {

    alignContent: "center",

    marginLeft: "10px",
    marginRight: "15px",
    "& div >  span": {
      color: "#4d4d4d",
    },
    "& div": {
      color: "#4d4d4d",
    }

  },
  errorAddCard: {
    background: "#ff174450",
    borderLeft: "7px solid #ff1744",
    width: "100%",
    padding: theme.spacing(1),
    direction: "ltr",
    borderRadius: "5px",
    fontSize: theme.spacing(1.6),
    // fontSize: theme.spacing(1.6),


  },
  layout: {
    alignContent: "center",
    justifyItems: "center",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  submit: {
    "& span": {
      fontFamily: "IRANSans",
    },
    marginTop: theme.spacing(4),
    bottom: theme.spacing(-2),
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(8),
    borderRadius: "8px",
    background: "#4CAF50",
    color: "white !important",
    boxShadow: '0 4px 8px   rgba(0,0,0,0.4)',
  },
  submitPassword: {
    "& span": {
      fontFamily: "IRANSans",
      fontSize: "12px",
      color: "white",

    },
    //  background:"#e62727",
    "&:hover": {

      background: "#0087c9"
    }

  },
  hoverHide: {
    "& span": {
      fontFamily: "IRANSans",
      fontSize: "12px",
      color: "white",

    },
    //  background:"#e62727",
    "&:hover": {

      background: "transparent"
    }

  },
  warringAddCard: {
    background: "#FFEEBC",
    width: "100%",
    padding: theme.spacing(1),
    borderLeft: "7px solid #FFCA28",
    direction: "ltr",
    borderRadius: "5px",
    fontSize: theme.spacing(1.6)

  },
  layoutSubmitCode: {
    borderRadius: "40px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    background: "#0087c9",
    backgroundImage: `url(${overHomeImage})`,
    backgroundSize: "contain",
    backgroundRepeat: 'repeat',
    backgroundPosition: 'right',
  },
  dialogError: {
    "& div>div": {
      overflowY: "visible !important",
    },

    background: "#0009"
  },
  circle: {

    borderRadius: "50%",
    background: "#fff",
    width: "80px",
    height: "80px",
    // border:"3px solid",
    position: "absolute",
    zIndex: "100",
    justifyItems: "center",
    alignContent: "center",
    border: "5px solid #ffffff",


    // position:"relative",
    marginTop: "10px",


  },
  successDialog: {

    borderLeft: "5px solid #4CAF50",
    marginTop: "10px",
    background: "#4CAF5040",
    padding: "4px",
    textAlign: "left",
    paddingLeft: "5px",
    fontSize: "14px",
    marginTop: "10px",
    borderRadius: "8px",


  },
  backBtn: {
    "& span": {
      fontFamily: "IRANSans",
    },
    borderRadius: theme.spacing(20),
    paddingRight: theme.spacing(0.5),
    marginRight: "5px",

  },
  boxMain: {
    boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    background: "#fff",
    WebkitBorderRadius: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: 0,
    margin: 0,
  },

  box: {
    // boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    // background: "red",
    // WebkitBorderRadius: theme.spacing(1),
    // borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  anim: {
    opacity: 1,
    transform: "scale(1)",
    transition: "opacity 800ms,transform 800ms",
  },
  input: {
    marginLeft: theme.spacing(5),
    margin: theme.spacing(3),
  },
  layoutTitle: {
    paddingTop: theme.spacing(1),
    // // background:"linear-gradient(176deg, rgba(144,164,174,1) 0%, rgba(38,50,56,1) 100%)",
    textAlign: "center",
    color: "white",
    paddingLeft: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(2.5),
    borderTopLeftRadius: theme.spacing(0.99),
  },
  imageSubmit: {
    backgroundImage: `url(${nextImage})`,
    backgroundSize: "contain",
    backgroundRepeat: 'no-repeat',
    width: "25px",
    height: "25px",
    backgroundPosition: 'center',
  },
}));

export default useStyles