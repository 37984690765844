import {
    UPDATE_INFO
} from '../actionTypes'


const infoState = {
    info: null,
};

const WebsiteInfoReducer = (state = infoState, action) => {


    switch (action.type) {
        case UPDATE_INFO: {
            const {
                info, actionRandom
            } = action.payload;
            return {
                info: info
            };
        }


        default: {
            return state;
        }
    }

}

export default WebsiteInfoReducer;