import React from 'react';

import { makeStyles, createMuiTheme, jssPreset, } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";



export const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});


export const theme1 = createMuiTheme({
  direction: "rtl",
  palette: {
    action: {
      disabledBackground: "	rgb(112,112,112)",
      // disabled: '#fff',
    },

    primary: {
      // light: will be calculated from palette.primary.main,
      // main: "#428bca",
      main: "rgb(57, 57, 57)",
      //  main:'rgba(102, 175, 233, 1)',
      contrastText: "#fff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    light: {
      // light: will be calculated from palette.primary.main,
      main: "#ffffff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#ffffff",
      main: "#4CAF50",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  btnHelp: {

    backgroundColor: "#039BE5 !important",
    borderRadius: "6px",
    color: "#fff !important",
    boxShadow: "inset 0 1px 1px #C1E6F9, 0 0 3px #039BE5",
    border: "none",
    "&:hover": {
      // color: "#039BE5 !important",
      border: "none !important",
      backgroundColor: "#03a5f4 !important",
      color: "#fff !important",
      // backgroundColor: "#039BE5",

    },

  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    //     backgroundColor: theme.palette.primary.main,
    minWidth: theme.spacing(20),
    maxWidth: theme.spacing(50),
    height: theme.spacing(12),
    // height: 0,
    //  paddingTop: '56.25%', // 16:9
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  box: {
    boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    background: "#fff",
    WebkitBorderRadius: theme.spacing(1),
    borderRadius: theme.spacing(1),
    padding: 0,
    margin: 0,
    // padding: theme.spacing(2),

    // marginRight:theme.spacing(3),
    // marginLeft:theme.spacing(3),
  },
}));