import {
    CHANGE_PROCESS_C2C,RESET_PROCESS_C2C
} from '../actionTypes'


const infoState = {
    process: 1,  
};

const UpdateC2CshowCardDepositsReducer = (state = infoState, action) => {

 
    switch (action.type) {
        case CHANGE_PROCESS_C2C: {
            const { 
                process, 
            } = action.payload;
            return { 
                process: process + state.process
            };
        }
        case RESET_PROCESS_C2C: {
            const { 
                process, 
            } = action.payload;
            return { 
                process: process  
            };
        }
       
        default: {
            return state;
        }
    }

}

export default UpdateC2CshowCardDepositsReducer;