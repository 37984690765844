import React, { useState, useEffect } from "react";
import { Grid, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import InputCard from "./InputCard";
import { Navigate, Link } from "react-router-dom";

import useStyles from "./Style";
import {
  setTheme,
  convertNumbers2EnglishString,
} from "../../../../tools/Tools";
import Api from "../../../../api/Api";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Timer from "../lastStepPage/Timer";
import WarningIcon from "@material-ui/icons/Warning";
import DialogSuccess from "../dialogs/DialogSuccess";
import DialogError from "../dialogs/DialogError";
import LoadingProcess from "../LoadingProcess";
import { useLocation } from "react-router-dom";
let time;
const PhonePage = ({ setLogo, setNavigate }) => {
  const { state } = useLocation();
  const classes = useStyles();
  const [phone, setPhone] = useState(null);
  const [loading, setloading] = useState(false);
  const [isMsgAddCard, setMsgAddCard] = useState(false);
  const [toNext, setToNext] = useState(false);
  const myRef = React.useRef(null);
  const [isShowBoxCode, setIsShowBoxCode] = useState(false);
  const [sendMsgCode, setSendMsgCode] = React.useState(true);
  const [
    gatewayAuthenticationCode,
    setGatewayAuthenticationCode,
  ] = React.useState("");
  const [isResult, setIsResult] = useState({
    result: false,
    msg: "",
    isSuccess: 2, // 0:error 1: warring 2: success
  });

  React.useEffect(() => {
    if (!JSON.parse(window.sessionStorage.getItem("isNationalcode")))
      setNavigate("loading")
    setTheme(setLogo);

    //window.addEventListener('popstate',  onBackButtonEvent);
  }, []);

  //  const  onBackButtonEvent = (e) => {

  //   }

  const [msg, setMsg] = useState("خطا از سمت سرور ");
  const showError = (msg) => {
    setloading(false);
    setIsResult({
      ...isResult,
      result: true,
      msg: msg,
      isSuccess: 0,
    });
  };

  const handleButtonSubmit = () => {

    if (phone && phone.length <= 15) {
      window.sessionStorage.setItem("nationalCode", phone);
      window.sessionStorage.setItem("isLastStep", true);
      setNavigate("last-step-page", state);
    } else
      showError('کدملی را وارد کنید')

  };

  const handleCloseDialogError = () => {
    setIsResult({ ...isResult, result: false });
    if (isResult.isSuccess == 2) {
      setInfoStartPayMent(10);
    }
  };









  const handleCloseDialogSuccess = () => {
    setIsResult({ ...isResult, result: false });
    time = new Date();
    time.setSeconds(time.getSeconds() + Number(isResult?.timer));
    setToNext(true);
    setSendMsgCode(true);
    setTimeout(() => {
      myRef.current.focus();
    }, 700);
  };


  const handleBackBtn = () => {
    setNavigate("setPhone");

  };

  const setInfoStartPayMent = (timeCount) => {
    setloading(false);

    setIsResult({
      ...isResult,
      result: true,
      msg: "کد برای شما ارسال شده است",
      isSuccess: 2,
      timer: timeCount,
    });
  };




  return (
    <Box style={{ paddingTop: "50px" }}>




      <Grid
        container
        spacing={0}
        className={`${classes.boxMain} ${loading && "blurBackground"}`}
        justify="center"
        alignContent="center"
        alignItems="center"
        style={{ position: "relative" }}
      >
        <LoadingProcess loading={loading} />

        {/* this is back btn */}
        <Grid container direction="row" justify="flex-start" xs={8}>
          <Button
            size="small"
            type="submit"
            variant="text"
            color="primary"
            onClick={handleBackBtn}
            className={classes.backBtn}
            startIcon={<ArrowBackIosIcon />}
          >
            بازگشت
          </Button>
        </Grid>

        <Grid
          item
          className={`${classes.layoutTitle} backgroundTitle un-selected`}
          xs={4}
        >
          احراز هویت
        </Grid>

        <Grid
          xs={10}
          className={classes.warringAddCard}
          style={{ marginTop: 20 }}
        >
          لطفا کد ملی مربوط به شماره موبایل و شماره کارت انتخاب شده خود را وارد نمایید
        </Grid>
        <Grid
          xs={10}
          className={classes.errorAddCard}
          style={{ marginTop: 10 }}
        >
          <span style={{ fontWeight: "900 !important", fontFamily: "IRANSans !important", fontSize: "14px" }}> کاربر گرامی</span>
          <br />
          به دلیل الزام تطابق کد ملی صاحب کارت و شماره موبایل برای استفاده از این درگاه (که از طرف بانک مرکزی اعمال شده است)، کد ملی شما تنها در جهت انجام عملیات پرداخت استفاده شده و در اختیار هیچ شخص ثالثی قرار نخواهد گرفت
        </Grid>
        <InputCard
          setPhone={setPhone}
          toNext={toNext}
          setToNext={setToNext}
          enterClick={handleButtonSubmit}
        />


        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            fullWidth
            color="secondary"
            className={classes.submit}
            onClick={handleButtonSubmit}
          >
            <Grid
              container
              xs={11}
              direction="row"
              justify="flex-end"
              alignContent="center"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item xs={6} style={{ textAlign: "center" }}>
                ادامه
              </Grid>
              <Grid
                item
                className="line"
                style={{
                  background: "#fff",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              ></Grid>
              <Grid item xs={3} className={classes.imageSubmit}></Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>

      {isResult.isSuccess == 2 ? (
        <DialogSuccess
          isResult={isResult}
          handleCloseDialog={handleCloseDialogSuccess}
        />
      ) : (
        <DialogError
          isResult={isResult}
          handleCloseDialog={handleCloseDialogError}
          setNavigate={setNavigate}
        />
      )}
    </Box>
  );
};
export default PhonePage;
