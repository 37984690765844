import {
  CHANGE_PROCESS_C2C, RESET_PROCESS_C2C, SHOW_ERROR_DIALOG, SHOW_WARRING_DIALOG, UPDATE_INFO
} from "./actionTypes";


export const changeProcessC2C = (process) => ({
  type: CHANGE_PROCESS_C2C,
  payload: {
    process: process,

  }
});

export const resetProcessC2C = (process) => ({
  type: RESET_PROCESS_C2C,
  payload: {
    process: process,

  }
});

export const setShowError = (isShow = false, data = null) => ({
  type: SHOW_ERROR_DIALOG,
  payload: {
    showErrorDialog: isShow ? Math.floor(Math.random() * 50) : -1,
    dataDialog: data,
  }
});

export const setShowWarring = (isShow = false, data = null) => ({
  type: SHOW_WARRING_DIALOG,
  payload: {
    showWarringDialog: isShow ? Math.floor(Math.random() * 50) : -1,
    dataDialog: data,
  }
});


export const setWebsiteInfo = (data = null) => ({
  type: UPDATE_INFO,
  payload: {
    actionRandom: Math.floor(Math.random() * 50),
    info: data,
  }
});