import React from 'react';
import {
    makeStyles, 
  } from "@material-ui/core/styles"; 

 export const useStyles = makeStyles((theme) => ({
    paper: {
      display: "flex",
      flexDirection: "column",
      //  alignItems: "center",
      //  alignContent:"center",
    },
    layout: {
      alignContent: "center",
      justifyItems: "center",
      textAlign: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      "& span": {
        fontFamily: "IRANSans",
      }, 
      bottom: theme.spacing(-2),
      marginRight: theme.spacing(5),
      marginLeft: theme.spacing(5),
      borderRadius: theme.spacing(5),
      background: "#4CAF50",
    },
    boxMain: {
      boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
      background: "#fff",
      WebkitBorderRadius: theme.spacing(1),
      borderRadius: theme.spacing(2),
      padding: 0,
      margin: 0,
      paddingRight:30,
      paddingLeft:30
    },
  
    box: {
      // boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
      // background: "red",
      // WebkitBorderRadius: theme.spacing(1),
      // borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
    },
    anim: {
      opacity: 1,
      transform: "scale(1)",
      transition: "opacity 800ms,transform 800ms",
    },
  }));