import React from 'react';


const LoadingPorcess=({loading})=>{

    return(
        <>
        {loading && (
            <div className="process backgroundPostionProcess">
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          </>
    )

}


export default LoadingPorcess