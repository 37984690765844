import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  TextField,
} from "@material-ui/core"; 
import { usePaymentInputs } from "react-payment-inputs";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    //  alignItems: "center",
    //  alignContent:"center",
  },
  layout: {
    //   alignContent: "center",
    //   justifyItems: "center",
    //   textAlign: "center",
   // background: "linear-gradient(90deg, #353535, #717171)",
    background: "#0b0000",
     
    borderRadius: theme.spacing(2.5),
    boxShadow: "3px 3px 3px rgba(0, 0, 0, .6)", 
    width:"90% !important"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    "& span": {
      fontFamily: "IRANSans",
    },
    marginTop: theme.spacing(4),
    bottom: theme.spacing(-2),
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(8),
    borderRadius: theme.spacing(5),
  },
  backBtn: {
    "& span": {
      fontFamily: "IRANSans",
    },
    borderRadius: theme.spacing(20),
    paddingRight: theme.spacing(0.5),
  },
  boxMain: {
    boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    background: "#fff",
    WebkitBorderRadius: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: 0,
    margin: 0,
  },

  box: {
    // boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    // background: "red",
    // WebkitBorderRadius: theme.spacing(1),
    // borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
  anim: {
    opacity: 1,
    transform: "scale(1)",
    transition: "opacity 800ms,transform 800ms",
  },
  input: {
    marginLeft: theme.spacing(5),
    margin: theme.spacing(3),
  },
  cardNumber: {

    paddingTop: theme.spacing(2),
    // background:"linear-gradient(176deg, rgba(144,164,174,1) 0%, rgba(38,50,56,1) 100%)",
    textAlign: "left",
    justifyContent: "left",
    color: "white", 
    opacity:"0.5",
    fontSize: theme.spacing(3),
  },
}));

const CardBank = (props) => {
  const classes = useStyles();
  const { numberCard, countPage, changeCountRow } = props;
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const [cardNumber, setCardNumber] = useState(null);

  const handleChangeCardNumber = () => {};

  React.useEffect(() => {}, []);

  return (
    <Grid container  justify="center" className={classes.layout}>
      <Grid item>{"name bank"}</Grid>
      <Grid item xs={12}>
        <div className="cardIcon">
          <hr className="cardIconLine"/>
          <hr className="cardIconLine"/>
          <hr className="cardIconLine"/> 
        </div>
      </Grid>
      <Grid item className={classes.cardNumber}>{"5487  1344  1324  6457"}</Grid>
     
    </Grid>
  );
};
CardBank.propTypes = {
  numberCard: PropTypes.number,
  countPage: PropTypes.number,
};
export default CardBank;
