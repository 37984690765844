import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import './assets/styles/app.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
ReactDOM.render(
  <React.StrictMode>

    <Provider store={store}>

      <HashRouter
      // basename={`/${window.location.href.split("/")[
      //   window.location.href
      //     .split("/")
      //     .findIndex((v) => v == "#") + 1
      // ]
      //   }/`}
      >
        <App />
      </HashRouter>
    </Provider>

  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
