import React from 'react'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import imageBack from '../../../../assets/images/bg-overlay-home1.png'
import overHomeImage from '../../../../assets/images/bg-overlay-home1.png'
export const useStyles = makeStyles((theme) => ({
    paper: {
      display: "flex",
      flexDirection: "column",
      //  alignItems: "center",
      //  alignContent:"center",
    },
    layout: {
      alignContent: "center",
      justifyItems: "center",
      textAlign: "center",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    smallIcon: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    titleSelectExpare:{
        color:"grey",
        fontSize:"14px",
    },
    submit: {
      "& span": {
        fontFamily: "IRANSans",
      },  
      borderRadius: "8px",
      background: "#4CAF50",
      color: "white !important",
      boxShadow: '0 4px 8px   rgba(0,0,0,0.4)',
     // marginBottom:"-25px",
      bottom:"-23px !important",
    },
    checkBoxRemember:{
      direction:"ltr",
      "& span":{
        fontSize: "14px",
      }
    },
    layoutButtones:{
     
    },
    submitPassword: {
      "& span": {
        fontFamily: "IRANSans",
      },  
      color: "white !important",
    },
    colorTimer:{ 
      "& div >  span":{
        color: "#2465FF ",
      },
      "& div":{
        color: "#2465FF ",
      }
    },
    backBtn: {
      "& span": {
        fontFamily: "IRANSans",
      },
      borderRadius: theme.spacing(20),
      paddingRight: theme.spacing(0.5),
    },
    boxMain: {
      boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
      background: "#fff",
      WebkitBorderRadius: theme.spacing(1),
      borderRadius: theme.spacing(1.5),
      padding: 0,
      margin: 0,
    },
  
    box: {
      padding: theme.spacing(2),
    },
    anim: {
      opacity: 1,
      transform: "scale(1)",
      transition: "opacity 800ms,transform 800ms",
    },
    input: {
      marginLeft: theme.spacing(5),
      margin: theme.spacing(3),
    },
    layoutTitle: {
      paddingTop: theme.spacing(1),
      paddingBottom:theme.spacing(1),
      // // background:"linear-gradient(176deg, rgba(144,164,174,1) 0%, rgba(38,50,56,1) 100%)",
      textAlign: "center",
      // justifyContent: "center",
      color: "white",
      // paddingBottom: theme.spacing(1),
      // bottom: theme.spacing(-30),
      backgroundColor: "rgba(57, 57, 57,1)",
     
      paddingLeft: theme.spacing(1), 
      borderTopLeftRadius: theme.spacing(1),
    },
    backgroundTimerOne:{
      background:"#f2f2f2",
      color:"black",
      borderBottomLeftRadius:"150px",
      borderBottomRightRadius:"150px",
      width:"80px !important",  
      height:"40px",
      paddingTop:"5px",
    },
    layoutTitle2: {
    
      paddingBottom:"15px",
      textAlign: "center",
      // justifyContent: "center",
      color: "white",
      // paddingBottom: theme.spacing(1),
      // bottom: theme.spacing(-30),
      background: "rgba(57, 57, 57,1)",
      paddingLeft: theme.spacing(1), 
      borderTopRightRadius: theme.spacing(1),
      backgroundImage: `url(${imageBack})`,
      backgroundSize:"contain",
      backgroundRepeat:'repeat',
      backgroundPosition: 'left',
    },
    headerText:{
      background:"white",
      textAlign:"center",
      borderRadius:"50px",
      boxShadow: '0 4px 8px   rgba(0,0,0,0.4)',
      color:"rgba(57, 57, 57,1)",
      paddingTop:"4px",
      paddingBottom:"4px",

    },
    headerLayout:{
      marginTop:"-17px",
    },
    lineVertical:{
      border: "1px dashed #c2c2c2", 
      width:"1px",
      height:"80px",
    },
    layoutSubmitCode:{
      borderRadius: "40px",
      borderTopRightRadius:"8px",
      borderBottomRightRadius:"8px",
      background:"#0087c9",
      backgroundImage: `url(${overHomeImage})`,
      backgroundSize:"contain",
      backgroundRepeat:'repeat',  
      backgroundPosition: 'right',
    },
    selectBoxMonthTitle:{
        position:"absolute", 
        zIndex:"100 !important",
        top:"10%",
        right:"80%"

    },
    copyBtn: {

      backgroundColor: "#039BE5",
      borderRadius: "6px",
      color: "white !important",
      boxShadow:"inset 0 1px 1px rgba(0,0,0,.02), 0 0 8px rgba(57, 57, 57, 0.2)",
      border:"none",
      "&:hover": {
        // color: "#039BE5 !important",
        border: "none !important",
        backgroundColor: "#027ab5 !important",
  
      },
      "&:focus": {
        // color: "#039BE5 !important",
        border: "none !important",
        backgroundColor: "#039BE5 !important",
  
      },
      backgroundImage: `url(${imageBack})`,
      backgroundSize:"contain",
      backgroundRepeat:'repeat',
      backgroundPosition: 'left',
      fontSize:"12px",
    },
    copyBtn2: {

      backgroundColor: "#4CAF50",
      borderRadius: "6px",
      color: "white !important",
      boxShadow:"inset 0 1px 1px rgba(0,0,0,.02), 0 0 8px rgba(57, 57, 57, 0.2)",
      border:"none",
      "&:hover": {
        // color: "#039BE5 !important",
        border: "none !important",
        backgroundColor: "#4CAF50 !important",
  
      },
      "&:focus": {
        // color: "#039BE5 !important",
        border: "none !important",
        backgroundColor: "#4CAF50 !important",
  
      },
     
      fontSize:"12px",
    },
    inputAmount: {
      border: "none",
      borderBottom: "none !important",
      background: "transparent !important",
  
      "& div:focus": {
        borderBottom: "none !important",
        background: "transparent !important",
      },
      "& :focus": {
        borderBottom: "none !important",
        background: "transparent !important",
      },
      "& div::before": {
        borderBottom: "none !important",
        background: "transparent !important",
      },
      "& ::after": {
        borderBottom: "none !important",
        background: "transparent !important",
      },
      margin: theme.spacing(0),
      paddingRight: theme.spacing(1),
    
    //  paddingLeft: theme.spacing(1),
    },
    small: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    menuItem: {
      "&:focus": {
        backgroundColor: "#4CAF50",
        color: "white",
        borderRight: "2px solid  rgb(57, 57, 57)",
        borderLeft: "2px solid  rgb(57, 57, 57)",
        //  borderRadius:"10px",
      },
    },
    dialog: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
  }));