import React, { Component, useState } from "react";
import {
  Grid,
  Button,
  Box,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import InputCard from "./InputCard";
import MuiAlert from "@material-ui/lab/Alert";
import { Navigate, Link } from "react-router-dom";
import useStyles from "./Style";
import { setTheme } from "../../../../tools/Tools";
import Api from "../../../../api/Api";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DialogError from "../dialogs/DialogError";
import DialogSuccess from "../dialogs/DialogSuccess";
import LoadingProcess from "../LoadingProcess";
import { showErrorRequest } from './../../../../tools/Tools';



const AddCard = ({ setLogo, setNavigate }) => {
  const classes = useStyles();
  const [cardNumber, setCardNumber] = useState(null);
  const [loading, setloading] = useState(false);
  const [enableBtn, setEnableBtn] = useState(false);
  const [isMsgAddCard, setMsgAddCard] = useState(false);
  const [isSelectMehtodAddCard, setIsSelectMehtodAddCard] = useState(false);
  const [isNextStep, setIsNextStep] = useState();
  const [isResult, setIsResult] = useState({
    result: false,
    msg: "",
    isSuccess: 0, // 0:error 1: warring 2: success
  });

  React.useEffect(() => {
    if (!JSON.parse(window.sessionStorage.getItem("addCard")))
      setNavigate("loading")
    setTheme(setLogo);
  }, []);
  const [msg, setMsg] = useState("خطا از سمت سرور ");

  const handleButtonSubmit = () => {
    if (cardNumber && cardNumber.length > 15) {
      setloading(true);
      setTimeout(() => {
        requestAddCard();
      }, 350);
    } else {
      setIsResult({
        ...isResult,
        result: true,
        msg: "شماره کارت را وارد کنید",
      });
    }
  };
  const handleCloseDialog = () => {
    setIsResult({ result: false });
  };
  const handleCloseSuccessDialog = () => {
    window.sessionStorage.setItem(
      "userCards",
      JSON.stringify(isResult.userCards)
    );
    window.sessionStorage.setItem("isMsgAddCard", true);

    setIsResult({ result: false });

    setTimeout(() => {
      if (JSON.parse(window.sessionStorage.getItem("addCard_StepStep"))) {
        goToNext()
      } else if (JSON.parse(window.sessionStorage.getItem("isSelectMethodAddCard")))
        setNavigate("selectMethod")
      else setNavigate("MsgAddCardPage");
    }, 300);
  };
  const goToNext = () => {
    if (window.sessionStorage.getItem("sessionType") == "withdrawal") {
      setNavigate("homePageWithdrawal")
    } else if (window.sessionStorage.getItem("method") == "CPG")
      setNavigate("home")
    else
      setNavigate("homeC2C")
  }

  const requestAddCard = async () => {
    try {
      const { status, data } = await Api.addCard(cardNumber);

      if (status === 200) {
        if (data.success) {
          sessionStorage.setItem('message', data.message)
          setIsResult({
            ...isResult,
            result: true,
            msg: data.message,
            isSuccess: 2,
            userCards: data.userCards,
          });
        } else {
          setIsResult({ ...isResult, result: true, msg: data.message });
        }
      } else {
        setIsResult({ ...isResult, result: true, msg: showErrorRequest(status) });
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      setIsResult({ ...isResult, result: true, msg: showErrorRequest(0) });
    }
  };


  return (
    <Box
      //height="65vh" 
      style={{ paddingTop: "50px" }}>

      <Grid
        container
        spacing={0}
        className={`${classes.boxMain} ${(loading || isResult.result) && "blurBackground"
          }`}
        style={{ position: "relative" }}
      >
        <LoadingProcess loading={loading} />
        <Grid container direction="row" justify="flex-start" xs={8}>
          {JSON.parse(window.sessionStorage.getItem("btn_back_addCard")) ? (
            <Button
              size="small"
              type="submit"
              variant="text"
              color="primary"
              onClick={goToNext}
              className={classes.backBtn}
              startIcon={<ArrowBackIosIcon />}
            >
              بازگشت
            </Button>
          ) :
            (
              <Button
                size="small"
                type="submit"
                variant="text"
                color="primary"
                onClick={() => {
                  // setIsNextStep(true);
                }}
                className={classes.backBtn}
                startIcon={<ArrowBackIosIcon />}
              >
                <a
                  href={
                    JSON.parse(window.sessionStorage.getItem("webSiteInfo"))
                      .websiteAddress
                  }
                  style={{ color: "rgb(57, 57, 57)", textDecoration: "none", direction: "rtl" }}
                  target="_self"
                >
                  {" "}
                  بازگشت به{" "}
                  {
                    JSON.parse(window.sessionStorage.getItem("webSiteInfo"))
                      .websiteName
                  }{" "}
                </a>
              </Button>
            )
          }
        </Grid>

        <Grid
          item
          className={`${classes.layoutTitle} backgroundTitle un-selected`}
          justify="center"
          justifyContent="center"
          xs={4}
        >
          افزودن کارت
        </Grid>

        <InputCard setEnableBtn={setEnableBtn} setCardNumber={setCardNumber} enterClick={handleButtonSubmit} />

        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        ></Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            fullWidth
            color="secondary"
            className={classes.submit}
            onClick={handleButtonSubmit}
          >
            <Grid
              container
              xs={11}
              direction="row"
              justify="flex-end"
              alignContent="center"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item xs={6} style={{ textAlign: "center" }}>
                ادامه
              </Grid>
              <Grid
                item
                className="line"
                style={{
                  background: "#fff",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              ></Grid>
              <Grid item xs={2} className={classes.imageSubmit}>
                <AddCircleOutlineIcon style={{ fontSize: "26px" }} />
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>

      {isResult.isSuccess == 2 ? (
        <DialogSuccess
          isResult={isResult}
          handleCloseDialog={handleCloseSuccessDialog}
        />
      ) : (
        <DialogError
          isResult={isResult}
          handleCloseDialog={handleCloseDialog}
          setNavigate={setNavigate}
        />
      )}
    </Box>
  );
};
export default AddCard;
