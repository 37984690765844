


export const isLoadingNeed = (timeLast) => {
  const timeNew = new Date().getMilliseconds()
  if (timeNew > timeLast) {
    const timeResult = timeNew - timeLast
    if (timeResult > 200)
      return true
    else return false
  }
  return false
}

export const setNumberCard = (value = "", index) => {
  if (index === 1)
    return value.substring(0, 2) + "**"
  else if (index === 2) return "****"
  else return value
}
export const timeLast = () => new Date().getMilliseconds()

export const replaceAllEnter = (value) => {
  const searchRegExp = /\\n/g;
  const replaceWith = '';
  return value.replace(searchRegExp, replaceWith)
}

export const showErrorRequest = (status) => {
  if (status === 400)
    return "خطای دریافت اطلاعات - لطفا مجددا تلاش نمایید";
  else if (status === 403)
    return "دسترسی غیرمجاز - لطفا مجددا تلاش نمایید";
  else if (status === 429)
    return "درخواست بیش از حد مجاز - لطفا چند ثانیه بعد مجددا تلاش نمایید";
  else if (status === 500)
    return "بروز خطای سیستمی - لطفا مجددا تلاش نمایید";
  else return "خطا در ارتباط - لطفا اتصال اینترنت خود را بررسی کرده و مجددا تلاش نمایید";
}



export const replaceAllSpace = (value) => {
  const searchRegExp = /\s/g;
  const replaceWith = '';
  return value.replace(searchRegExp, replaceWith)
}
export const containsNumber = (myString) => {
  if (myString) {
    return /\d/.test(myString);
  } else
    return ""
}
export const setTheme = (setLogo) => {
  const theme_ = window.sessionStorage.getItem("webSiteInfo");
  let isSet = JSON.parse(window.sessionStorage.getItem("isSet"));

  const theme = JSON.parse(theme_)
  if (!isSet && theme) {
    window.sessionStorage.setItem("isSet", true)
    document.body.style.backgroundColor = theme.themeOptions["bg-color"];
    // document.getElementById(
    //     "background"
    // ).style.backgroundColor = ` linear-gradient(90deg, ${theme.themeOptions["bg-gradient-1"]} 0%,${theme.themeOptions["bg-gradient-2"]} 50%, ${theme.themeOptions["bg-gradient-1"]} 100%)`;
    document.getElementById(
      "background"
    ).style.backgroundColor = `#303030`;

    setLogo(theme.websiteLogo);
  }
}
export const isPhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);
export const resulation = (window.screen.width * window.devicePixelRatio) + (window.screen.height * window.devicePixelRatio);
export const getNumberCardFromatter = (number) => {

  return number.replace(/\W/gi, "").replace(/(.{4})/g, "$1 - ").slice(0, -3);
};
export const getShabaNumberFromatter = (number) => {


  return number.replace(/\W/gi, "").replace(/(.{4})/g, "$1  ");
};

export const getArrayNumberCard = (number) => {
  let numberCard = number.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ").split(" ")
  numberCard.pop()
  return numberCard
};

export const getListYear = () => {

  //let today = new Date().toLocaleDateString('fa-IR');
  //let year = convertNumbers2English(today.split("/")?.[0])
  let year = 1400;
  let listYear = [];
  for (let i = year; i < year + 10; i++) {
    let year_ = `${i}`;
    //  listYear.push(year_.substring(2, 4)); 

    listYear.push(year_);
  }
  return listYear
}


export const convertNumberToPice = (number) => {

  let nStr = number + '';
  nStr = nStr.replace(/\,/g, "");
  let x = nStr.split('.');
  let x1 = x[0];
  let x2 = x.length > 1 ? '.' + x[1] : '';
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return (x1 + x2);

};


export const convertDateToFormat = (date) => {

  const searchRegExp = /-/g;
  const replaceWith = '/';

  date = date.replace(searchRegExp, replaceWith).replace(" ", "\xa0\xa0\xa0\xa0\xa0\xa0");
  return date.substring(0, date.length - 3)

}
export const convertNumbers2English = (string) => {
  return Number(string.replace(/[\u0660-\u0669]/g, function (c) {
    return c.charCodeAt(0) - 0x0660;
  }).replace(/[\u06f0-\u06f9]/g, function (c) {
    return c.charCodeAt(0) - 0x06f0;
  }));
}

export const convertNumbers2EnglishString = (string) => {
  try {
    return string.replace(/[\u0660-\u0669]/g, function (c) {
      return c.charCodeAt(0) - 0x0660;
    }).replace(/[\u06f0-\u06f9]/g, function (c) {
      return c.charCodeAt(0) - 0x06f0;
    });
  } catch (e) {
    return 0;
  }
}
