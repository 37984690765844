import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid, 
  Button, 
  Dialog, 
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { replaceAllEnter} from '../../../../tools/Tools'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props}  timeout={{ enter: 450, exit: 450,}}/>;
});

const useStyles = makeStyles((theme) => ({
  dialogLayout: {
    "& div>div": {
      overflowY: "visible !important",
    },

    background: "#0009",

  },
  circleIcon: {
    borderRadius: "50%",
    background: "#fff",
    width: "80px",
    height: "80px",
    // border:"3px solid",
    position: "absolute",
    zIndex: "100",
    justifyItems: "center",
    alignContent: "center",
    border: "5px solid #ffffff",
    background: "#d32f2f",

    // position:"relative",
    marginTop: "10px",
  },
  successDialog: {
    borderLeft: "5px solid #4CAF50",
    marginTop: "10px",
    background: "#4CAF5040",
    padding: "4px",
    textAlign: "left",
    paddingLeft: "5px",
    fontSize: "14px",
    marginTop: "10px",
    borderRadius: "8px",
  },
  iconDialog: {
    fontSize: "52px",
    color: "#fff",
  },
  titleDialog: {
    fontSize: "20px",
    color: "#3b3b3b",
    marginTop: "15px",
    textAlign: "center",
  },
  textDialog: {
    textAlign: "center",
    marginTop: "10px",
    width: "100%",
    color: "#757575",
    marginLeft: "15px",
    marginRight:"15px",
  },
  layoutButtonDialog:{
    marginTop: "20px",
    color: "#4d4d4d",
    minWidth: "300px",
  },
  btnClose:{
    color:"white",
    width:"50%",
    textAlign:"center",
    paddingTop:"7px",
    paddingBottom:"8px",
    fontSize:"14px",
    background:"#4a4a4a",
    borderBottomRightRadius:3,
    pointerEvents: "auto",
  },
  btnOk:{
    background:"#FFA000",
    width:"50%",
    textAlign:"center",
    paddingTop:"7px",
    paddingBottom:"8px",
    color:"white",
    fontSize:"14px",
    backgroundColor: "#039BE5",
    borderBottomLeftRadius:3,
    pointerEvents: "auto",
  //  borderLeft: "5px solid #ffffff",
  },
}));
const DialogWarring = (props) => {

  const classes = useStyles();
  const { isResult, handleCloseDialog , handleCloseAndYesDialog} = props;
  const [infoSite, setInfoSite] = useState({});


  useEffect(() => {
    const info = window.sessionStorage.getItem("webSiteInfo");
    if (info) setInfoSite(JSON.parse(info));
  }, []);
  return (
    <Dialog
      open={isResult.result}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.dialogLayout}
    >
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid
          container
          className={classes.circleIcon}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <CloseIcon className={classes.iconDialog} />
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        justify="center"
        alignContent="center"
        justifyContent="center"
        className="un-selected"
        style={{ marginTop: "40px", color: "dark", minWidth: "250px" }}
      >
        <Grid item className={classes.titleDialog}>
          {" "}
          خطا
        </Grid>
        <div className={`${classes.textDialog}  un-selected`} style={{direction:"rtl"}}>
          {" "}
          {  isResult.msg.split('\\n').map(function(item, idx) {
        return (
            <span key={idx}>
                {replaceAllEnter(item)}
                <br/>
            </span>
         )
    })}
           {" "}
        </div>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
       className={classes.layoutButtonDialog} 
      >
       <Grid item  xs={5} className={classes.btnClose} >
       <a
                  href={  infoSite.websiteAddress}
                  style={{
                    color: "white",
                    textDecoration: "none",
                    direction: "rtl",
                  }}
                  target="_self"
                >
                   بازگشت به سایت
                </a>
      
       </Grid>
       <Grid item xs={7} className={classes.btnOk} onClick={ handleCloseAndYesDialog}>
       مشاهده تاریخچه درخواست
       </Grid>
      </Grid>
    </Dialog>
  );
};
DialogWarring.propTypes = {
  isResult: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};
export default DialogWarring;
